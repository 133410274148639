import styled from 'styled-components';

export const StyledSubtleText = styled.p`
  ${({ theme }) => theme.objects.Copy.Subtle}
  font-size: ${({ theme }) => theme.values.textSizes.XSMALL};
`;

export const StyledHeading = styled.p`
  ${({ theme }) => theme.objects.Heading.Scale.MEDIUM}
  ${({ theme }) => theme.objects.Heading.Style.Primary}
`;

export const StyledOrganisationSettings = styled.div`
  ${({ theme }) => theme.mixins.Container('COLUMN')}
`;
