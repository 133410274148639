import { Auth0Provider } from '@auth0/auth0-react';
import { DesignSystemProvider, themes, ThemeType } from '@finbb/ui-components';
import { memo } from 'react';
import { ThemeProvider } from 'styled-components';
import Router from './components/Router/Router';
import StateProvider from './context/StateProvider/StateProvider';
import useConfig from './hooks/useConfig';
import './i18n/i18n';

const App = () => {
  const theme = themes.light as ThemeType;

  const { auth0Audience, auth0ClientId, auth0Domain } = useConfig();

  if (!auth0Domain || !auth0ClientId) {
    throw Error('Auth0 configuration is missing!');
  }

  return (
    <Auth0Provider
      authorizationParams={{
        audience: auth0Audience,
        redirect_uri: window.location.origin,
        scope: 'read:current_user',
      }}
      clientId={auth0ClientId}
      domain={auth0Domain}
      useRefreshTokens
    >
      <StateProvider>
        <ThemeProvider theme={theme}>
          <DesignSystemProvider theme={theme}>
            <Router />
          </DesignSystemProvider>
        </ThemeProvider>
      </StateProvider>
    </Auth0Provider>
  );
};

export default memo(App);
