import { useFormikContext } from 'formik';
import { memo, useState } from 'react';

import { LoadingIndicator, MultiSelect } from '@finbb/ui-components';
import useEndpoint from '../../hooks/useEndpoint';
import { ExpertProfileCategoriesApi } from '../../routes/ApiDefinitions';
import { ExportProfileCategory, ExportProfileCategoryType } from '../../types/ExpertProfile.types';
import { filterSimilarStrings } from '../../utils/data';

type PropTypes = {
  name: string;
  label: string;
  placeholder: string;
  value: string[];
  category: ExportProfileCategory;
  required?: boolean;
  onChanged?: (values: string[]) => void;
};

const getCategoryEndpoint = (category?: ExportProfileCategory) => {
  switch (category) {
    case ExportProfileCategory.TherapyAreas:
      return 'therapy-area';
    case ExportProfileCategory.ResearchExpertises:
      return 'research-expertise';
    case ExportProfileCategory.Roles:
      return 'role';
    case ExportProfileCategory.ResearchPhases:
      return 'research-phase';
    case ExportProfileCategory.Icd10Codes:
      return 'icd-10';
    case ExportProfileCategory.RareNeurologicalDiseases:
      return 'rare-neurological-disease';
    default:
      return '';
  }
};

const CategoryMultiSelect = ({
  category,
  name,
  label,
  placeholder,
  value,
  required,
  onChanged,
}: PropTypes) => {
  const [inputValue, setInputValue] = useState<string>();
  const { setFieldValue, errors } = useFormikContext<{
    [key: string]: string[];
  }>();

  const [{ data, status }] = useEndpoint<ExportProfileCategoryType[]>(
    {
      method: 'GET',
      options: { callImmediately: true },
      url: `${ExpertProfileCategoriesApi.path}/${getCategoryEndpoint(category)}`,
    },
    []
  );

  const onChange = (values: string[]) => {
    setFieldValue(name, values).then(() => {
      if (onChanged) {
        onChanged(values);
      }
    });
  };

  const options = data.map((entry) => ({
    name: entry.name,
    value: entry.id,
  }));

  const filteredOptions =
    inputValue !== '' && inputValue !== undefined
      ? filterSimilarStrings({
          filterWith: inputValue,
          filterFrom: options,
          getFilterValue: (entry) => entry.name,
          similarityThreshold: 0.7,
          sortBySimilarity: true,
        })
      : options;

  if (status === 'LOADING') {
    return <LoadingIndicator />;
  }

  return (
    <>
      {status === 'SUCCESS' && (
        <MultiSelect
          name={name}
          label={label}
          value={value}
          placeholder={placeholder}
          required={required}
          onChanged={onChange}
          onInputChanged={setInputValue}
          allowNewValues={false}
          options={filteredOptions}
          variant={errors[name] ? 'CAUTION' : 'NORMAL'}
        />
      )}
    </>
  );
};

export default memo(CategoryMultiSelect);
