import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useEndpoint from '../../hooks/useEndpoint';
import { OrganisationApi } from '../../routes/ApiDefinitions';
import { OrganisationDto } from '../../types/Organisation.types';
import OrganisationForm from '../OrganisationForm/OrganisationForm';
import { StyledOrganisationSettings } from './OrganisationSettings.styles';
import { OrganisationSettingsType, RouteParamsType } from './OrganisationSettings.types';

const OrganisationSettings = ({ id: idProp }: OrganisationSettingsType) => {
  const { t } = useTranslation();
  const { id: idParam } = useParams<RouteParamsType>();
  const id = idProp || idParam;

  const [{ data: organisation }] = useEndpoint<OrganisationDto>(
    {
      method: 'GET',
      options: { blockRenderingWhileLoading: true, callImmediately: true },
      url: `${OrganisationApi.path}/${id}`,
    },
    {} as OrganisationDto
  );

  if (!organisation.id) {
    return <div>{t('Errors.organisationNotFound')}</div>;
  }

  return (
    <StyledOrganisationSettings>
      <OrganisationForm organisation={organisation} />
    </StyledOrganisationSettings>
  );
};

export default memo(OrganisationSettings);
