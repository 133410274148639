import { useAuth0 } from '@auth0/auth0-react';
import jwtDecode from 'jwt-decode';
import { useContext, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { StateContext } from '../../context/StateProvider/StateProvider';
import useEndpoint from '../../hooks/useEndpoint';
import useErrors from '../../hooks/useErrors';
import DashboardPage from '../../pages/DashboardPage/DashboardPage';
import LogoutPage from '../../pages/LogoutPage/LogoutPage';
import OrganisationManagementPage from '../../pages/OrganisationManagementPage/OrganisationManagementPage';
import PrivacyPolicyPage from '../../pages/PrivacyPolicyPage/PrivacyPolicyPage';
import ProjectManagementPage from '../../pages/ProjectManagementPage/ProjectManagementPage';
import SignupPage from '../../pages/SignupPage/SignupPage';
import UserManagementPage from '../../pages/UserManagementPage/UserManagementPage';
import { UserApi } from '../../routes/ApiDefinitions';
import {
  ExpertProfiles,
  Logout,
  Organisations,
  OwnOrganisation,
  PrivacyPolicy,
  Projects,
  Root,
  Signup,
  Users,
} from '../../routes/RouteDefinitions';
import { PartialAccessTokenType } from '../../types/Auth0.types';
import { RouteActions } from '../../types/Route.types';
import { UserDto } from '../../types/User.types';
import { getRouteActionPath, isAllowedRoute } from '../../utils/routes';
import OrganisationSettings from '../OrganisationSettings/OrganisationSettings';
import ExpertProfileManagementPage from '../../pages/ExpertProfileManagementPage/ExpertProfileManagementPage';

const AuthenticatedSwitch = (): JSX.Element => {
  const { getAccessTokenSilently, isAuthenticated, user: auth0User } = useAuth0();
  const { dispatch, state } = useContext(StateContext);
  const handleError = useErrors();
  const history = useNavigate();

  const [{ status }, getUser] = useEndpoint<UserDto>(
    {
      options: { blockRenderingWhileLoading: true },
      url: `${UserApi.path}/${auth0User?.sub}`,
    },
    {} as UserDto
  );

  useEffect(() => {
    const setUserPermissions = async () => {
      try {
        if (isAuthenticated) {
          const accessToken = await getAccessTokenSilently();
          const { permissions } = jwtDecode<PartialAccessTokenType>(accessToken);
          dispatch({ type: 'SET_PERMISSIONS', payload: permissions });
        }
      } catch (error) {
        dispatch({ type: 'SET_PERMISSIONS', payload: null });
        handleError('Error getting access token from Auth0.');
      }
    };

    setUserPermissions();
  }, [dispatch, getAccessTokenSilently, handleError, isAuthenticated]);

  useEffect(() => {
    const setUserData = async () => {
      try {
        if (state.permissions?.includes('read:own-user')) {
          const dbUser = await getUser();
          dispatch({ type: 'SET_USER', payload: dbUser });
        }
      } catch (error) {
        handleError('Error reading user data.');
      }
    };

    setUserData();
  }, [dispatch, getUser, handleError, state.permissions]);

  useEffect(() => {
    if (isAuthenticated && state.permissions?.length === 0) {
      history(getRouteActionPath(Signup, RouteActions.PENDING));
    }
  }, [history, isAuthenticated, state.permissions]);

  const userHasAnOrganisation = Boolean(state.user?.organisationId);

  /*
  In some cases fetching user data seems to fail.
  If this happens, force user to logout.
  TODO: Implement some generic error page with retry button
  */
  useEffect(() => {
    if (status === 'ERROR') {
      history(Logout.path);
    }
  }, [history, status]);

  return (
    <Routes>
      {isAllowedRoute(Organisations, state.permissions) && (
        <Route path={`${Organisations.path}/*`} element={<OrganisationManagementPage />} />
      )}
      {isAllowedRoute(OwnOrganisation, state.permissions) && userHasAnOrganisation && (
        <Route
          path={OwnOrganisation.path}
          element={<OrganisationSettings id={state.user?.organisationId} />}
        />
      )}
      {isAllowedRoute(Projects, state.permissions) && (
        <Route path={`${Projects.path}/*`} element={<ProjectManagementPage />} />
      )}
      <Route path={`${Users.path}/*`} element={<UserManagementPage />} />
      <Route path={`${ExpertProfiles.path}/*`} element={<ExpertProfileManagementPage />} />

      <Route path={PrivacyPolicy.path} element={<PrivacyPolicyPage />} />
      <Route path={Signup.path} element={<SignupPage />} />
      <Route path={Logout.path} element={<LogoutPage />} />
      <Route path={Root.path} element={<DashboardPage />} />
    </Routes>
  );
};

export default AuthenticatedSwitch;
