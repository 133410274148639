import { AnySchema, array, mixed, string } from 'yup';
import i18n from '../i18n/i18n';
import { languages, LanguageType } from '../i18n/i18n.types';
import { userRoles, RoleType } from '../types/Role.types';
import { BaseUserDto } from '../types/User.types';
import {
  lowercaseRegex,
  numberRegex,
  phoneRegex,
  specialCharRegex,
  uppercaseRegex,
} from '../utils/strings';

export const PasswordShape = {
  password: string()
    .min(10, i18n.t('ValidationErrors.stringTooShort'))
    .matches(uppercaseRegex, i18n.t('ValidationErrors.passwordMustHaveUppercase'))
    .matches(lowercaseRegex, i18n.t('ValidationErrors.passwordMustHaveLowercase'))
    .matches(numberRegex, i18n.t('ValidationErrors.passwordMustHaveNumber'))
    .matches(specialCharRegex, i18n.t('ValidationErrors.passwordMustHaveSpecialChar'))
    .required(i18n.t('ValidationErrors.required')),
  passwordConfirm: string()
    .test(
      'passwords-match',
      i18n.t('ValidationErrors.passwordsMustMatch'),
      (value, context) => context.parent.password === value
    )
    .required(i18n.t('ValidationErrors.required')),
};

export const UserShape: Record<keyof BaseUserDto, AnySchema> = {
  email: string()
    .min(2, i18n.t('ValidationErrors.stringTooShort'))
    .max(50, i18n.t('ValidationErrors.stringTooLong'))
    .email(i18n.t('ValidationErrors.email'))
    .required(i18n.t('ValidationErrors.required')),
  firstName: string()
    .min(1, i18n.t('ValidationErrors.stringTooShort'))
    .max(80, i18n.t('ValidationErrors.stringTooLong'))
    .required(i18n.t('ValidationErrors.required')),
  lang: mixed<LanguageType>()
    .required(i18n.t('ValidationErrors.required'))
    .oneOf([...languages]),
  lastName: string()
    .min(1, i18n.t('ValidationErrors.stringTooShort'))
    .max(80, i18n.t('ValidationErrors.stringTooLong'))
    .required(i18n.t('ValidationErrors.required')),
  phone: string()
    .matches(phoneRegex, i18n.t('ValidationErrors.invalidPhoneNumber'))
    .required(i18n.t('ValidationErrors.required')),
  organisationId: string(),
  organisationName: string(),
  collaborationOrganisationId: string(),
};

export const RoleShape = {
  roles: array()
    .of(
      mixed<RoleType>()
        .oneOf([...userRoles])
        .required(i18n.t('ValidationErrors.required'))
    )
    .min(1, i18n.t('ValidationErrors.required'))
    .required(i18n.t('ValidationErrors.required')),
};
