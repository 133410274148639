/* eslint @typescript-eslint/no-explicit-any: "off" */
import React, { memo } from 'react';
import { Select } from '@finbb/ui-components';

import useEndpoint from '../../hooks/useEndpoint';
import { OrganisationDto } from '../../types/Organisation.types';
import { CollaborationOrganisationApi, OrganisationApi } from '../../routes/ApiDefinitions';

interface PropTypes {
  label: string;
  onBlur: (...args: any[]) => void;
  onChange: (...args: any[]) => void;
  placeholder: string;
  value: string;
  required?: boolean;
  name?: string;
  organisationCategory?: 'collaboration' | 'service';
}

const UserOrganisationSelect = ({
  label,
  onBlur,
  onChange,
  placeholder,
  value,
  required,
  name,
  organisationCategory,
}: PropTypes) => {
  const [{ data: organisations }] = useEndpoint<OrganisationDto[]>(
    {
      method: 'GET',
      options: { blockRenderingWhileLoading: true, callImmediately: true },
      url:
        organisationCategory === 'collaboration'
          ? CollaborationOrganisationApi.path
          : OrganisationApi.path,
      queryParams: {
        organisationCategory: organisationCategory === 'service' ? 'service' : undefined,
      },
    },
    []
  );

  const organisationNameOptions = organisations.map((x) => ({ name: x.name, value: x.id }));

  return (
    <Select
      value={value}
      label={label}
      name={name ?? 'organisationId'}
      onBlur={onBlur}
      onChange={onChange}
      options={organisationNameOptions}
      placeholder={placeholder}
      required={required}
    />
  );
};

export default memo(UserOrganisationSelect);
