import { PermissionType } from '../types/Permission.types';
import { NavigationRouteType, RouteActionType, RouteType } from '../types/Route.types';

export const getRouteActionPath = (route: RouteType, action: RouteActionType): string => {
  if (route.actions?.includes(action)) {
    return `${route.path}/${action}`;
  }

  return route.path;
};

export const isAllowedRoute = (
  route: NavigationRouteType,
  userPermissions: PermissionType[] | null
): boolean => {
  if (userPermissions === null) {
    return false;
  }

  const userHasPermission = (permission: PermissionType) => userPermissions.includes(permission);

  const userHasAllPermissions = route.requiredPermissions?.all?.every(userHasPermission);
  const userHasAtLeastOnePermissions =
    route.requiredPermissions?.atLeastOne?.some(userHasPermission);

  const isProhibited = [userHasAllPermissions, userHasAtLeastOnePermissions].includes(false);

  return !isProhibited;
};
