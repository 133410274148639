import { Input, Select, SelectOptionType } from '@finbb/ui-components';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { organisationTypes, OrganisationTypeType } from '../../../types/Organisation.types';
import { StyledFieldSet } from '../SignupForm.styles';
import { PartialFormType } from '../SignupForm.types';

const OrganisationInfo = ({ errors, onBlur, onChange, touched, values }: PartialFormType) => {
  const { t } = useTranslation();

  const organisationTypeOptions: SelectOptionType<OrganisationTypeType>[] = organisationTypes.map(
    (type) => ({
      name: t(`OrganisationTypes.${type}`),
      value: type,
    })
  );

  return (
    <StyledFieldSet>
      <Input
        label={t('Labels.name')}
        message={
          errors.suggestedOrganisation?.name && touched.suggestedOrganisation?.name
            ? errors.suggestedOrganisation?.name
            : ''
        }
        name="suggestedOrganisation.name"
        onBlur={onBlur}
        onChange={onChange}
        required
        value={values.suggestedOrganisation.name}
        variant={
          errors.suggestedOrganisation?.name && touched.suggestedOrganisation?.name
            ? 'CAUTION'
            : undefined
        }
      />
      <Select
        label={t('Labels.organisationType')}
        message={
          errors.suggestedOrganisation?.organisationType &&
          touched.suggestedOrganisation?.organisationType
            ? errors.suggestedOrganisation?.organisationType
            : ''
        }
        name="suggestedOrganisation.organisationType"
        onBlur={onBlur}
        onChange={onChange}
        options={organisationTypeOptions}
        placeholder={t('Labels.select')}
        required
        value={values.suggestedOrganisation.organisationType}
        variant={
          errors.suggestedOrganisation?.organisationType &&
          touched.suggestedOrganisation?.organisationType
            ? 'CAUTION'
            : undefined
        }
      />
      <Input
        label={t('Labels.address')}
        message={
          errors.suggestedOrganisation?.address && touched.suggestedOrganisation?.address
            ? errors.suggestedOrganisation?.address
            : ''
        }
        name="suggestedOrganisation.address"
        onBlur={onBlur}
        onChange={onChange}
        required
        value={values.suggestedOrganisation.address}
        variant={
          errors.suggestedOrganisation?.address && touched.suggestedOrganisation?.address
            ? 'CAUTION'
            : undefined
        }
      />
      <Input
        label={t('Labels.zip')}
        message={
          errors.suggestedOrganisation?.zip && touched.suggestedOrganisation?.zip
            ? errors.suggestedOrganisation?.zip
            : ''
        }
        name="suggestedOrganisation.zip"
        onBlur={onBlur}
        onChange={onChange}
        required
        value={values.suggestedOrganisation.zip}
        variant={
          errors.suggestedOrganisation?.zip && touched.suggestedOrganisation?.zip
            ? 'CAUTION'
            : undefined
        }
      />
      <Input
        label={t('Labels.city')}
        message={
          errors.suggestedOrganisation?.city && touched.suggestedOrganisation?.city
            ? errors.suggestedOrganisation?.city
            : ''
        }
        name="suggestedOrganisation.city"
        onBlur={onBlur}
        onChange={onChange}
        required
        value={values.suggestedOrganisation.city}
        variant={
          errors.suggestedOrganisation?.city && touched.suggestedOrganisation?.city
            ? 'CAUTION'
            : undefined
        }
      />
      <Input
        label={t('Labels.countryCode')}
        message={
          errors.suggestedOrganisation?.countryCode && touched.suggestedOrganisation?.countryCode
            ? errors.suggestedOrganisation?.countryCode
            : ''
        }
        name="suggestedOrganisation.countryCode"
        onBlur={onBlur}
        onChange={onChange}
        placeholder="FI, US, DE, UK, GB ..."
        required
        value={values.suggestedOrganisation.countryCode}
        variant={
          errors.suggestedOrganisation?.countryCode && touched.suggestedOrganisation?.countryCode
            ? 'CAUTION'
            : undefined
        }
      />
    </StyledFieldSet>
  );
};

export default memo(OrganisationInfo);
