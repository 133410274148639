import { AnySchema, string } from 'yup';
import i18n from '../i18n/i18n';
import { CreateProjectDto } from '../types/Project.types';

export const ProjectShape: Record<keyof CreateProjectDto, AnySchema> = {
  description: string().required(i18n.t('ValidationErrors.required')),
  organisationId: string().required(i18n.t('ValidationErrors.required')),
  projectId: string(),
  projectCoordinatorAuth0Id: string().required(i18n.t('ValidationErrors.required')),
  projectResearcherAuth0Id: string().required(i18n.t('ValidationErrors.required')),
};
