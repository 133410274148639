import { Button } from '@finbb/ui-components';
import { Formik } from 'formik';
import { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { StateContext } from '../../context/StateProvider/StateProvider';
import useEndpoint from '../../hooks/useEndpoint';
import { UserApi } from '../../routes/ApiDefinitions';
import { Signup } from '../../routes/RouteDefinitions';
import { RouteActions } from '../../types/Route.types';
import { CreateUserDto, UserDto } from '../../types/User.types';
import { getRouteActionPath } from '../../utils/routes';
import OrganisationInfo from './partials/OrganisationInfo';
import UserInfo from './partials/UserInfo';
import { SignupFormSchema } from './SignupForm.schemas';
import { StyledHeading, StyledSignupForm } from './SignupForm.styles';
import { SignupFormContentType, SignupFormType } from './SignupForm.types';

const SignupForm = ({ organisation }: SignupFormType) => {
  const { t } = useTranslation();
  const history = useNavigate();
  const { dispatch } = useContext(StateContext);

  const [createResponse, createUser] = useEndpoint<UserDto>(
    {
      method: 'POST',
      options: { noAuth: true },
      url: UserApi.path,
    },
    {} as UserDto
  );

  const initialState: SignupFormContentType = {
    email: '',
    firstName: '',
    lang: 'en',
    lastName: '',
    password: '',
    passwordConfirm: '',
    phone: '',
    suggestedOrganisation: {
      address: organisation?.address || '',
      city: organisation?.city || '',
      countryCode: organisation?.countryCode || '',
      name: '',
      organisationType: organisation?.organisationType || 'academic',
      zip: organisation?.zip || '',
    },
  };

  const handleSubmit = async (
    values: SignupFormContentType,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    const submitState: CreateUserDto = {
      email: values.email,
      firstName: values.firstName,
      lang: values.lang,
      lastName: values.lastName,
      password: values.password,
      phone: values.phone,
      suggestedOrganisation: values.suggestedOrganisation,
    };

    if (createResponse.status === 'IDLE' || createResponse.status === 'ERROR') {
      try {
        await createUser(submitState);
        setSubmitting(false);
        history(getRouteActionPath(Signup, RouteActions.SUCCESS));
      } catch (error) {
        dispatch({ type: 'SET_ERROR_MESSAGE', payload: t('Errors.signUpFailed') });
      }
    }
    history(getRouteActionPath(Signup, RouteActions.SUCCESS));
  };

  return (
    <Formik
      initialValues={initialState}
      onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
      validationSchema={SignupFormSchema}
    >
      {({ errors, handleBlur, handleChange, touched, values }) => (
        <StyledSignupForm>
          <StyledHeading>{t('Headings.userInformation')}</StyledHeading>
          <UserInfo
            errors={errors}
            onBlur={handleBlur}
            onChange={handleChange}
            touched={touched}
            values={values}
          />

          {organisation === undefined && (
            <>
              <StyledHeading>{t('Headings.organisationInformation')}</StyledHeading>

              <OrganisationInfo
                errors={errors}
                onBlur={handleBlur}
                onChange={handleChange}
                touched={touched}
                values={values}
              />
            </>
          )}

          <Button text={t('Actions.submit')} type="submit" variant="CALL_TO_ACTION" />
        </StyledSignupForm>
      )}
    </Formik>
  );
};

export default memo(SignupForm);
