import { useCallback, useEffect, useState } from 'react';

type ErrorType = Error | string;
type HandlerFunctionType = (error: ErrorType) => void;

const useErrors = (): HandlerFunctionType => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handler = useCallback((error: ErrorType) => {
    if (typeof error === 'string') {
      setErrorMessage(error);
    } else {
      setErrorMessage(error.message);
    }
  }, []);

  useEffect(() => {
    if (errorMessage) {
      // TODO: Display errors to the user
    }
  }, [errorMessage]);

  return handler;
};

export default useErrors;
