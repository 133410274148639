import { createContext, memo, useEffect, useReducer } from 'react';
import { ComponentWithChildren } from '../../types/Component.types';
import { ContextType, StateType } from './StateProvider.types';
import StateReducer from './StateReducer';

const STATE_PERSISTENCE_KEY = 'applicationState';

export const initialState: StateType = {
  isUserTabbingAround: false,
  pendingCalls: 0,
  permissions: null,
  persistToLocalStorage: false,
  user: null,
  errorMessage: null,
};

const initializeState = (): StateType => {
  const persistedState = JSON.parse(
    localStorage.getItem(STATE_PERSISTENCE_KEY) || 'null'
  ) as StateType;

  return persistedState || initialState;
};

export const StateContext = createContext({} as ContextType);

const StateProvider = ({ children }: ComponentWithChildren) => {
  const [state, dispatch] = useReducer(StateReducer, initializeState());

  useEffect(() => {
    if (state.persistToLocalStorage) {
      localStorage.setItem(STATE_PERSISTENCE_KEY, JSON.stringify(state));
    }
  }, [state]);

  return <StateContext.Provider value={{ dispatch, state }}>{children}</StateContext.Provider>;
};

export default memo(StateProvider);
