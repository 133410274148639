import { ButtonGroup } from '@finbb/ui-components';
import { memo } from 'react';
import {
  StyledActionableMessage,
  StyledHeading,
  StyledMessageContent,
  StyledText,
} from './ActionableMessage.styles';
import { ActionableMessageType } from './ActionableMessage.types';

const ActionableMessage = ({ heading, message, buttons }: ActionableMessageType) => (
  <StyledActionableMessage>
    <StyledMessageContent>
      <StyledHeading>{heading}</StyledHeading>
      <StyledText>{message}</StyledText>
    </StyledMessageContent>
    {buttons !== undefined && <ButtonGroup buttons={buttons} />}
  </StyledActionableMessage>
);

export default memo(ActionableMessage);
