import { useAuth0 } from '@auth0/auth0-react';
import { ButtonType } from '@finbb/ui-components';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ActionableMessage from '../../components/ActionableMessage/ActionableMessage';
import CenteredParagraph from '../../components/CenteredParagraph/CenteredParagraph';
import { Signup } from '../../routes/RouteDefinitions';

const LandingPage = () => {
  const { loginWithPopup } = useAuth0();
  const history = useNavigate();
  const { t, i18n } = useTranslation();

  const handleLoginClick = () =>
    loginWithPopup({ authorizationParams: { ui_locales: i18n.language } });
  const handleSignupClick = () => history(Signup.path);

  const buttons: ButtonType[] = [
    {
      onClick: handleLoginClick,
      text: t('Actions.login'),
      variant: 'CALL_TO_ACTION',
    },
    {
      onClick: handleSignupClick,
      text: t('Actions.signup'),
    },
  ];

  return (
    <>
      <ActionableMessage
        buttons={buttons}
        heading={t('Headings.welcome')}
        message={t('Messages.welcome')}
      />

      <CenteredParagraph text={t('Routes.privacyPolicy')} link="/privacy-policy" openInNewTab />
    </>
  );
};

export default memo(LandingPage);
