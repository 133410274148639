import { LanguageType } from '../i18n/i18n.types';
import { BaseOrganisationDto, OrganisationDto } from './Organisation.types';
import { RoleType } from './Role.types';

export const userStates = ['pending', 'accepted', 'rejected'] as const;
export type UserStateType = (typeof userStates)[number];
export type UserStateObjectType = { [K in UserStateType]: K };
export const userState: UserStateObjectType = userStates.reduce(
  (states, stateName: UserStateType) => ({
    ...states,
    [stateName]: stateName,
  }),
  {} as UserStateObjectType
);

export interface BaseUserDto {
  email: string;
  firstName: string;
  lang: LanguageType;
  lastName: string;
  phone: string;
  organisationId?: string;
  organisationName?: string;
  collaborationOrganisationId?: string;
}

export interface CreateUserDto extends BaseUserDto {
  password: string;
  suggestedOrganisation: BaseOrganisationDto;
}

export type UpdateUserDto = Partial<BaseUserDto> & {
  relatedBiobankId?: string;
};

export interface UserDto extends BaseUserDto {
  auth0Id: string;
  id: string;
  organisationId?: string;
  roles?: RoleType[];
  state: UserStateType;
  suggestedOrganisation?: OrganisationDto;
  organisation?: OrganisationDto;
  expertProfileId?: string;
  relatedBiobankId?: string;
  expertProfileCreationConsentGivenAt?: string;
}

export type ProjectUserType = Pick<UserDto, 'auth0Id' | 'firstName' | 'id' | 'lastName'>;
