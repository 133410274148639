import { mixed, object, SchemaOf, string } from 'yup';
import i18n from '../../i18n/i18n';
import { UserApprovalFormContentType } from './UserApprovalForm.types';
import { RoleType, userRoles } from '../../types/Role.types';

export const UserApprovalFormSchema: SchemaOf<UserApprovalFormContentType> = object({
  organisationId: string().required(i18n.t('ValidationErrors.required')),
  role: mixed<RoleType>()
    .required(i18n.t('ValidationErrors.required'))
    .oneOf([...userRoles]),
});
