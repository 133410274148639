import { useAuth0 } from '@auth0/auth0-react';
import { ButtonType } from '@finbb/ui-components';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import ActionableMessage from '../ActionableMessage/ActionableMessage';

const SignupPending = () => {
  const { logout } = useAuth0();
  const { t } = useTranslation();
  const buttons: ButtonType[] = [
    {
      text: t('Actions.logout'),
      onClick: () => logout(),
      variant: 'CALL_TO_ACTION',
    },
  ];

  return (
    <ActionableMessage
      heading={t('Headings.signupPending')}
      message={t('Messages.signupPending')}
      buttons={buttons}
    />
  );
};

export default memo(SignupPending);
