import { StateType } from '../../context/StateProvider/StateProvider.types';
import useEndpoint from '../../hooks/useEndpoint';
import { UserDto } from '../../types/User.types';
import { UserApi } from '../../routes/ApiDefinitions';

const useUsers = (state: StateType, hasExpertProfile?: true) => {
  const [{ data: users }] = useEndpoint<UserDto[]>(
    {
      method: 'GET',
      options: { blockRenderingWhileLoading: true, callImmediately: true },
      queryParams: {
        organisationId: !state.permissions?.includes('read:all-organisations')
          ? state.user?.organisationId
          : undefined,
        hasExpertProfile: hasExpertProfile ? 'true' : undefined,
      },
      url: UserApi.path,
    },
    []
  );
  return users;
};

export default useUsers;
