import { AnySchema, mixed, string } from 'yup';
import i18n from '../i18n/i18n';
import {
  CreateOrganisationDto,
  organisationCategories,
  OrganisationCategoryType,
  organisationTypes,
  OrganisationTypeType,
} from '../types/Organisation.types';
import { iso3166alpha2List } from '../utils/strings';

export const OrganisationShape: Record<keyof CreateOrganisationDto, AnySchema> = {
  address: string().required(i18n.t('ValidationErrors.required')),
  city: string().required(i18n.t('ValidationErrors.required')),
  code: string()
    .required(i18n.t('ValidationErrors.required'))
    .max(10, i18n.t('ValidationErrors.stringTooLong')),
  countryCode: string()
    .uppercase()
    .min(2, i18n.t('ValidationErrors.countryCode'))
    .max(2, i18n.t('ValidationErrors.countryCode'))
    .oneOf(iso3166alpha2List, i18n.t('ValidationErrors.countryCode'))
    .required(i18n.t('ValidationErrors.required')),
  name: string().required(i18n.t('ValidationErrors.required')),
  organisationCategory: mixed<OrganisationCategoryType>()
    .required(i18n.t('ValidationErrors.required'))
    .oneOf([...organisationCategories]),
  organisationType: mixed<OrganisationTypeType>()
    .required(i18n.t('ValidationErrors.required'))
    .oneOf([...organisationTypes]),
  zip: string().required(i18n.t('ValidationErrors.required')),
};
