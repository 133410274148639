import styled, { createGlobalStyle } from 'styled-components';

interface StyledMainContentType {
  isInactive: boolean;
}

export const PlatformWebStyles = createGlobalStyle`
  header {
    z-index: 2;
  }
`;

export const StyledMainContent = styled.main<StyledMainContentType>`
  ${({ theme }) => theme.mixins.Container('COLUMN')}
  ${({ theme }) =>
    theme.mixins.Transition({ duration: theme.values.durations.NORMAL, target: 'opacity' })}

  opacity: ${({ isInactive }) => (isInactive ? 0 : 1)};
  visibility: ${({ isInactive }) => (isInactive ? 'hidden' : 'visible')};
  width: 100%;
`;

export const StyledBasicLayout = styled.div`
  margin: 0 auto;
  max-width: ${({ theme }) => theme.values.dimensions.MAIN_CONTENT_MAX_WIDTH};
  padding: ${({ theme }) => theme.values.spacing.MEDIUM};
  width: 100%;
`;
