import { Form } from 'formik';
import styled from 'styled-components';

export const StyledSubtleText = styled.p`
  ${({ theme }) => theme.objects.Copy.Subtle}
  font-size: ${({ theme }) => theme.values.textSizes.XSMALL};
`;

export const StyledHeading = styled.p`
  ${({ theme }) => theme.objects.Heading.Scale.MEDIUM}
  ${({ theme }) => theme.objects.Heading.Style.Primary}
`;

export const StyledFieldSet = styled.fieldset`
  ${({ theme }) => theme.objects.Fieldset.Base}
`;

export const StyledForm = styled(Form)`
  ${({ theme }) => theme.mixins.Container('COLUMN')}
`;

export const StyledValue = styled.dd`
  margin: 0;
`;

export const StyledLabel = styled.dt`
  font-weight: ${({ theme }) => theme.values.fontWeights.MEDIUM};

  &::after {
    content: ': ';
  }
`;

export const StyledSuggestedData = styled.dl`
  ${({ theme }) => theme.mixins.Container('COLUMN')}
  ${({ theme }) => theme.objects.Border.Base}
  background-color: ${({ theme }) => theme.values.colors.brand.SUBTLE};
  padding: ${({ theme }) => theme.values.spacing.MEDIUM};
  grid-template-columns: 10rem 2fr;
`;

export const StyledSuggestion = styled.div`
  ${({ theme }) => theme.mixins.Container('ROW')}
`;

export const StyledUserApprovalForm = styled.div`
  ${({ theme }) => theme.mixins.Container('COLUMN')}
`;
