import { useEffect } from 'react';

const useDebouncedEffect = (effect: () => unknown, deps: Array<unknown>, delay: number): void => {
  useEffect(() => {
    const handler = setTimeout(() => effect(), delay);

    return () => clearTimeout(handler);
    // This disable next line is required unless you want to see a warning because lint will always complain
    // about not having effect as a dependency. Adding effect as a dependency will break the functionality
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(deps || []), delay]);
};

export default useDebouncedEffect;
