import { Button, Input, Select, SelectOptionType, SeparatorLine } from '@finbb/ui-components';
import { Formik, FormikErrors, FormikState } from 'formik';
import { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { StateContext } from '../../context/StateProvider/StateProvider';
import useEndpoint from '../../hooks/useEndpoint';
import { languages, LanguageType } from '../../i18n/i18n.types';
import { UserApi } from '../../routes/ApiDefinitions';
import { Users } from '../../routes/RouteDefinitions';
import { AddRoleDto, adminOrganisationRoles, genericRoles, RoleType } from '../../types/Role.types';
import { UpdateUserDto, UserDto } from '../../types/User.types';
import { XhrResponseType } from '../../types/XmlHttpRequest.types';
import { getChangedFormikValue } from '../../utils/data';
import { UserInfoFormContentType, UserInfoFormSchema } from './UserInfoForm.schemas';
import {
  StyledBadge,
  StyledButtonRow,
  StyledErrorMessage,
  StyledFieldSet,
  StyledHeading,
  StyledRow,
  StyledSuccessMessage,
  StyledUserInfoForm,
} from './UserInfoForm.styles';
import { UserInfoFormType } from './UserInfoForm.types';
import UserOrganisationSelect from './UserOrganisationSelect';
import RoleMultiSelect from './RoleMultiSelect';

const formatFormikError = (
  error: string | string[] | FormikErrors<unknown> | FormikErrors<unknown>[]
): string => {
  if (Array.isArray(error)) {
    return error.join(', ');
  }

  return error.toString();
};

const compareRolesAreEqual = (roles: RoleType[], initialValues: RoleType[]): boolean => {
  if (roles.length !== initialValues.length) {
    return false;
  }

  return roles.every((role) => initialValues.includes(role));
};

const UserInfoForm = ({ isOwnAccount, user }: UserInfoFormType) => {
  const { t } = useTranslation();
  const { state } = useContext(StateContext);
  const initialRoles = user.roles ?? ['researcher'];
  const history = useNavigate();

  const [patchResponse, patchUser] = useEndpoint<UserDto>(
    {
      method: 'PATCH',
      url: `${UserApi.path}/${user.auth0Id}`,
    },
    {} as UserDto
  );

  const [, patchUserRole] = useEndpoint<XhrResponseType>(
    {
      method: 'PUT',
      url: `${UserApi.path}/${user.auth0Id}/role`,
    },
    '200'
  );

  const [, requestPasswordChange] = useEndpoint<XhrResponseType>(
    {
      method: 'PUT',
      url: `${UserApi.path}/${user.auth0Id}/password`,
    },
    '200'
  );

  const initialState: UserInfoFormContentType = {
    email: user.email,
    firstName: user.firstName,
    lang: user.lang,
    lastName: user.lastName,
    roles: initialRoles,
    phone: user.phone,
    organisationId: user.organisationId || '',
    organisationName: user.organisation?.name || '',
    relatedBiobankId: user.relatedBiobankId || '',
    collaborationOrganisationId: user.collaborationOrganisationId || '',
  };

  const languageOptions: SelectOptionType<LanguageType>[] = languages.map((language) => ({
    name: t(`Languages.${language}`),
    value: language,
  }));

  const userCanUpdateAllRoles = state.permissions?.includes('update:all-user-roles');

  const usableRoles: RoleType[] = [
    ...(userCanUpdateAllRoles ? adminOrganisationRoles : []),
    ...genericRoles,
  ];

  const availableRoles: RoleType[] = [
    // If the user's current role is not in `usableRoles`, add it to the available options
    ...(user.roles && !usableRoles.includes(user.roles[0]) ? user.roles : []),
    ...usableRoles,
  ];

  const handlePasswordChangeRequest = async () => {
    // TODO: Change this to a custom modal component once we have one available in @finbb/ui-components
    // eslint-disable-next-line no-alert
    const isConfirmed = window.confirm(t('Messages.confirmPasswordChange'));

    if (isConfirmed) {
      await requestPasswordChange();
      // TODO: Notify the user of a successfully sent password change request
    }
  };

  const handleSubmit = async (
    values: UserInfoFormContentType,
    setSubmitting: (isSubmitting: boolean) => void,
    resetForm: (nextState?: Partial<FormikState<UserInfoFormContentType>>) => void
  ) => {
    if (user.roles && !compareRolesAreEqual(values.roles, user.roles)) {
      const roleDto: AddRoleDto = {
        roles: values.roles,
      };

      await patchUserRole(roleDto);
    }
    const shouldRelatedBiobankBeRemoved =
      !values.roles.includes('related-biobank-steering-group') &&
      user.roles?.includes('related-biobank-steering-group');

    const relatedBiobankIdFormik = shouldRelatedBiobankBeRemoved
      ? { relatedBiobankId: null }
      : getChangedFormikValue('relatedBiobankId', values, initialState);

    const submitValues: UpdateUserDto = {
      ...getChangedFormikValue('email', values, initialState),
      ...getChangedFormikValue('firstName', values, initialState),
      ...getChangedFormikValue('lang', values, initialState),
      ...getChangedFormikValue('lastName', values, initialState),
      ...getChangedFormikValue('phone', values, initialState),
      ...getChangedFormikValue('organisationId', values, initialState),
      ...getChangedFormikValue('relatedBiobankId', values, initialState),
      ...getChangedFormikValue('collaborationOrganisationId', values, initialState),
      ...relatedBiobankIdFormik,
    };

    await patchUser(submitValues);
    setSubmitting(false);

    if (!isOwnAccount) {
      history(Users.path);
    }

    resetForm({
      values,
    });
  };

  return (
    <Formik
      initialValues={initialState}
      onSubmit={(values, { setSubmitting, resetForm }) =>
        handleSubmit(values, setSubmitting, resetForm)
      }
      validationSchema={UserInfoFormSchema}
      enableReinitialize
    >
      {({ dirty, errors, handleBlur, handleChange, isSubmitting, isValid, touched, values }) => (
        <StyledUserInfoForm>
          <StyledRow>
            <StyledHeading>{t('Headings.userInformation')}</StyledHeading>
            <StyledBadge state={user.state}>{t(`AccountStates.${user.state}`)}</StyledBadge>
          </StyledRow>

          <StyledFieldSet>
            <Input
              label={t('Labels.firstName')}
              message={
                errors.firstName && touched.firstName ? formatFormikError(errors.firstName) : ''
              }
              name="firstName"
              onBlur={handleBlur}
              onChange={handleChange}
              required
              value={values.firstName}
              variant={errors.firstName && touched.firstName ? 'CAUTION' : undefined}
            />

            <Input
              label={t('Labels.lastName')}
              message={
                errors.lastName && touched.lastName ? formatFormikError(errors.lastName) : ''
              }
              name="lastName"
              onBlur={handleBlur}
              onChange={handleChange}
              required
              value={values.lastName}
              variant={errors.lastName && touched.lastName ? 'CAUTION' : undefined}
            />

            <Select
              label={t('Labels.preferredLanguage')}
              name="lang"
              onBlur={handleBlur}
              onChange={handleChange}
              options={languageOptions}
              placeholder={t('Labels.select')}
              required
              value={values.lang}
            />

            <SeparatorLine />
          </StyledFieldSet>

          <StyledFieldSet>
            <Input
              label={t('Labels.email')}
              message={errors.email && touched.email ? formatFormikError(errors.email) : ''}
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              required
              type="email"
              value={values.email}
              variant={errors.email && touched.email ? 'CAUTION' : undefined}
            />

            <Button
              onClick={handlePasswordChangeRequest}
              disabled={isSubmitting}
              text={t('Actions.changePassword')}
              type="button"
              variant="BASE"
            />

            <Input
              label={t('Labels.phoneIntlFormat')}
              message={errors.phone && touched.phone ? formatFormikError(errors.phone) : ''}
              name="phone"
              onBlur={handleBlur}
              onChange={handleChange}
              required
              value={values.phone}
              variant={errors.phone && touched.phone ? 'CAUTION' : undefined}
            />
          </StyledFieldSet>

          <SeparatorLine />

          {state.permissions?.includes('update:all-user-roles') && (
            <UserOrganisationSelect
              label={t('Labels.organisation')}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={t('Labels.select')}
              required
              value={values.organisationId as string}
            />
          )}

          {!state.permissions?.includes('update:all-user-roles') && (
            <Input
              label={t('Labels.organisation')}
              message={
                errors.organisationId && touched.organisationId
                  ? formatFormikError(errors.organisationId)
                  : ''
              }
              onBlur={handleBlur}
              onChange={handleChange}
              readOnly
              value={values.organisationName as string}
              variant={undefined}
            />
          )}

          {(state.permissions?.includes('read:all-collaboration-organisations') ||
            state.permissions?.includes('read:all-organisations')) && (
            <UserOrganisationSelect
              label={t('Labels.collaborationOrganisation')}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={t('Labels.select')}
              organisationCategory="collaboration"
              name="collaborationOrganisationId"
              value={values.collaborationOrganisationId as string}
            />
          )}

          {(state.permissions?.includes('update:all-user-roles') ||
            state.permissions?.includes('update:organisation-user-roles')) && (
            <RoleMultiSelect value={values.roles} fieldName="roles" roles={availableRoles} />
          )}

          {!state.permissions?.includes('update:all-user-roles') &&
            !state.permissions?.includes('update:organisation-user-roles') && (
              <Input
                label={t('Labels.userRole')}
                message={errors.roles && touched.roles ? errors.roles[0] : ''}
                onBlur={handleBlur}
                onChange={handleChange}
                readOnly
                value={values.roles.join(', ')}
                variant={undefined}
              />
            )}

          {state.permissions?.includes('update:all-user-roles') &&
            values.roles.includes('related-biobank-steering-group') && (
              <UserOrganisationSelect
                label={t('Labels.relatedBiobank')}
                name="relatedBiobankId"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={t('Labels.select')}
                required
                organisationCategory="service"
                value={values.relatedBiobankId as string}
              />
            )}

          <StyledButtonRow>
            <Button
              disabled={!(isValid && dirty) || isSubmitting}
              text={t('Actions.save')}
              type="submit"
              variant="CALL_TO_ACTION"
            />
            {patchResponse.status === 'SUCCESS' && (
              <StyledSuccessMessage>{t('Messages.informationSaved')}</StyledSuccessMessage>
            )}
            {patchResponse.status === 'ERROR' && (
              <StyledErrorMessage>{t('Errors.saveFailed')}</StyledErrorMessage>
            )}
          </StyledButtonRow>
        </StyledUserInfoForm>
      )}
    </Formik>
  );
};

export default memo(UserInfoForm);
