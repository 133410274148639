import { useFormikContext } from 'formik';
import { memo, useState } from 'react';

import { MultiSelect } from '@finbb/ui-components';
import useEndpoint from '../../hooks/useEndpoint';
import { KeywordApi } from '../../routes/ApiDefinitions';
import { PaginatedKeywordsResponseType } from '../../types/Keyword.types';

type PropTypes = {
  label: string;
  name: string;
  placeholder: string;
  value: string[];
  required?: boolean;
};

const KeywordMultiSelect = ({ name, label, placeholder, value, required }: PropTypes) => {
  const [inputValue, setInputValue] = useState<string | undefined>();

  const { setFieldValue, errors } = useFormikContext<{
    [key: string]: string[];
  }>();

  const [
    {
      data: { results: matchedKeywords },
    },
  ] = useEndpoint<PaginatedKeywordsResponseType>(
    {
      method: 'GET',
      options: { callImmediately: true },
      queryParams: { query: inputValue },
      url: KeywordApi.path,
    },
    { results: [], pageInfo: { total: 0, count: 0, offset: 0, limit: 0, hasNextPage: false } },
    inputValue
  );

  const options = matchedKeywords.map((entry) => ({
    name: entry.value,
    value: entry.value,
  }));

  return (
    <MultiSelect
      name={name}
      label={label}
      value={value}
      placeholder={placeholder}
      required={required}
      onChanged={(updatedValue) => setFieldValue(name, updatedValue)}
      onInputChanged={setInputValue}
      allowNewValues
      options={options}
      variant={errors[name] ? 'CAUTION' : 'NORMAL'}
    />
  );
};

export default memo(KeywordMultiSelect);
