import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import BulletList from './BulletListComponent';
import NumberList from './NumberListComponent';

/* eslint-disable react/jsx-no-literals */

const PrivacyPolicyEnglish = () => (
  <>
    <h1>PRIVACY NOTICE</h1>
    <p>
      <i>Updated 22nd May 2024</i>
    </p>

    <p>We are committed to protecting your privacy. </p>

    <h2>Data Controller and Data Protection Officer</h2>
    <p>
      Finnish Biobank Cooperative – FINBB, Regus, Yliopistonkatu 31, 20100 Turku, Finland, business
      id-code 2853268-2. You can reach FINBB:s data protection officer by emailing
      tietosuojavastaava@finbb.fi{' '}
    </p>

    <h2>Purpose and Legal Bases of Processing</h2>
    <p>We may process your personal data for the following purposes on the following grounds: </p>
    <NumberList>
      <li>
        to provide the FINGENIOUS® -services, including processing applications to biobanks made
        through the services.
        <br />
        <BulletList>
          <li>
            This processing is based on the Regulation (EU) 2016/679 of the European Parliament and
            of the Council of 27 April 2016 on the protection of natural persons with regard to the
            processing of personal data and on the free movement of such data, and repealing
            Directive 95/46/EC (General Data Protection Regulation, ‘GDPR’) Article 6 sub-paragraph
            1 point b: processing is necessary for the performance of a contract to which the data
            subject is party or in order to take steps at the request of the data subject prior to
            entering into a contract.
          </li>
        </BulletList>
      </li>
      <br />
      <li>
        to monitor and ensure the proper operations of our online services, for example to prevent
        or investigate unauthorised use, and to address claims made against us.
        <BulletList>
          <li>
            This processing is based on the GDPR Article 6 sub-paragraph 1 point f: processing is
            necessary for the purposes of the legitimate interests pursued by the controller or by a
            third party.
          </li>
        </BulletList>
      </li>
      <br />
      <li>
        to provide information about our services.
        <BulletList>
          <li>
            This processing is on our legitimate interest to keep our users informed (GDPR Article 6
            sub- paragraph 1 point f). You can opt-out from receiving these messages at any time.
            For those who specifically subscribe to our newsletter or other information, we process
            personal data based on consent (GDPR Article 6 subparagraph 1 point a) You can withdraw
            your consent at any time.{' '}
          </li>
        </BulletList>
      </li>
      <br />
      <li>
        to organise events and to provide information about events.
        <BulletList>
          <li>
            This processing is based on our legitimate interest to keep our users informed (GDPR
            Article 6 sub- paragraph 1 point f) or consent (GDPR Article 6 subparagraph 1 point a).
            You can opt-out from receiving messages or withdraw your consent at any time.
          </li>
        </BulletList>
      </li>
    </NumberList>

    <p>
      Providing personal data is required for using services with registration, like FINGENIOUS
      services for making applications for biobank samples and associated data. It is required also
      for subscribing to information and to participate in events.
    </p>

    <h2>Cookies on the Fingenious.fi site </h2>
    <p>
      We use cookies to personalize content and ads, to provide social media features, and to
      analyse our traffic. We use the information provided by cookies, e.g., for targeting ads in
      social media platforms and in Google Ads. We also use the cookie data in Google Analytics and
      Google Tag Manager. We also share information about your use of our site with our social
      media, advertising, and analytics partners who may combine it with other information that
      you’ve provided to them or that they’ve collected from your use of their services.
    </p>
    <p>
      Visitors can use the Cookiebot service to manage cookies on the website. You can manage the
      cookie settings easily by clicking the round icon on the left-down corner. You can find more
      information on what data is collected using the cookie settings in the icon in the left-down
      corner
    </p>

    <h2>Personal Data Collected</h2>
    <p>We collect the following types of personal data:</p>
    <h4>At online-services registration and use we may collect your</h4>
    <BulletList>
      <li>Name and title</li>
      <li>E-mail address</li>
      <li>Address</li>
      <li>Telephone number</li>
      <li>Represented organisation (applicant organisation) and its type (academic, industry) </li>
      <li>Selected language option</li>
      <li>IP-address</li>
    </BulletList>
    <h4>In the application forms we may also collect</h4>
    <BulletList>
      <li>Names and organisations of the researchers participating in the research project</li>
      <li>
        Names, addresses, e-mail addresses and represented organisations of the person responsible
        for the research project and of the persons authorised to sign contracts.
      </li>
    </BulletList>
    <h4>For newsletter subscriptions in Contentful cloud service, we may collect</h4>
    <BulletList>
      <li>Name and title</li>
      <li>E-mail address</li>
      <li>Telephone number</li>
      <li>Represented organisation </li>
      <li>Voluntary comments by the subscriber</li>
      <li>Consent information</li>
    </BulletList>

    <h4>For events we may collect</h4>
    <BulletList>
      <li>Name and title</li>
      <li>E-mail address</li>
      <li>Telephone number</li>
      <li>Represented organisation </li>
      <li>Diet preferences</li>
      <li>Event photos and video recordings</li>
      <li>Consent information</li>
    </BulletList>

    <h4>
      For the experts catalogue, in addition to the above listed online-services registration and
      use data we may collect
    </h4>
    <BulletList>
      <li>Freeform expertise keywords</li>
      <li>Therapy areas</li>
      <li>Research expertise</li>
      <li>Work role </li>
      <li>Research phases</li>
      <li>Diagnosis expertise (ICD10)</li>
      <li>Rare disease expertise</li>
    </BulletList>

    <h2>Recipients of Personal Data</h2>
    <p>
      We share the applications and the personal data included in them only with the biobank
      operators, registries and authorities to whom the applications are addressed.{' '}
    </p>
    <p>
      We may use information technology and other subcontractors (processors) to provide our
      services and to manage personal data. Some of these subcontractors may reside outside the
      European Union or the European Economic Area in countries, where the European data protection
      laws do not apply. In such cases, sufficient data security and protection are secured by
      contracts using terms approved by the EU Commission and other appropriate technical and
      organisational means.
    </p>

    <h2>Storage of Personal Data</h2>
    <p>
      We will store personal data as long as they are needed for the purpose for which they have
      been collected.{' '}
    </p>

    <h2>Your Rights</h2>
    <p>Each person whose personal data we process has the rights provided in the GDPR:</p>
    <BulletList>
      <li>right to withdraw your consent</li>
      <li>right to be informed of the processing</li>
      <li>right of access to the personal data</li>
      <li>right to rectification of personal data</li>
      <li>right to demand erasure of personal data</li>
      <li>right to restriction of processing of personal data</li>
      <li>right to data portability</li>
      <li>right to object processing of personal data</li>
      <li>right to lodge a complaint with a supervisory authority</li>
    </BulletList>
    <h4>The Finnish supervisory authority is:</h4>
    <p>
      Office of the data protection ombudsman
      <br />
      Visiting address: Ratapihantie 9, 00520 Helsinki, Finland
      <br />
      Postal address: P.O. Box 800, 00521 Helsinki, Finland
      <br />
      <a href="mailto:tietosuoja@om.fi">tietosuoja@om.fi.</a>
    </p>

    <p>This Privacy Notice may be updated from time to time.</p>
  </>
);

const PrivacyPolicyFinnish = () => (
  <>
    <h1>TIETOSUOJASELOSTE</h1>
    <p>
      <i>Päivitetty 27.6.2024</i>
    </p>

    <p>Olemme sitoutuneet suojelemaan yksityisyyttäsi.</p>

    <h2>Rekisterinpitäjä ja tietosuojavastaava</h2>
    <p>Voimme käsitellä henkilötietojasi seuraaviin tarkoituksiin seuraavin perustein:</p>
    <NumberList>
      <li>
        Tarjotaksemme FINGENIOUS® -palveluita, kuten palveluiden kautta tehtyjen biopankkihakemusten
        käsittely.
        <br />
        <BulletList>
          <li>
            Tämä käsittely perustuu EU:n yleisen tietosuoja-asetuksen (GDPR, 2016/679) 6 artiklan 1
            kohdan b alakohtaan: käsittely on tarpeen sellaisen sopimuksen täyttämiseksi, jossa
            rekisteröity on osapuolena tai jotta voidaan ryhtyä toimenpiteisiin rekisteröidyn
            pyynnöstä ennen sopimuksen tekemistä.
          </li>
        </BulletList>
      </li>
      <br />
      <li>
        Valvoaksemme ja varmistaaksemme verkkopalveluidemme asianmukaisen toiminnan, esimerkiksi
        luvattoman käytön estämiseksi tai tutkimiseksi sekä meitä vastaan esitettyjen vaatimusten
        käsittelemiseksi.
        <br />
        <BulletList>
          <li>
            Tämä käsittely perustuu GDPR:n artiklan 6 alakohdan 1 alakohtaan f: käsittely on tarpeen
            rekisterinpitäjän tai kolmannen osapuolen oikeutettujen etujen toteuttamiseksi.
          </li>
        </BulletList>
      </li>
      <br />
      <li>
        Palveluistamme tiedottamiseen.
        <br />
        <BulletList>
          <li>
            Tämä käsittely perustuu oikeutettuun etuumme pitää käyttäjämme ajan tasalla (GDPR
            artiklan 6 alakohdan 1 alakohta f). Voit kieltäytyä vastaanottamasta näitä viestejä
            milloin tahansa. Niiden osalta, jotka tilaavat uutiskirjeemme tai muita tietoja,
            käsittelemme henkilötietoja suostumuksen perusteella (GDPR artiklan 6 alakohdan 1
            alakohta a). Voit peruuttaa suostumuksesi milloin tahansa.
          </li>
        </BulletList>
      </li>
      <br />
      <li>
        Järjestääksemme tapahtumia ja tiedottaaksemme niistä.
        <br />
        <BulletList>
          <li>
            Tämä käsittely perustuu oikeutettuun etuumme pitää käyttäjämme ajan tasalla (GDPR
            artiklan 6 alakohdan 1 alakohta f) tai suostumukseen (GDPR artiklan 6 alakohdan 1
            alakohta a). Voit kieltäytyä vastaanottamasta viestejä tai peruuttaa suostumuksesi
            milloin tahansa.
          </li>
        </BulletList>
      </li>
    </NumberList>

    <p>
      Henkilötietojen antaminen on välttämätöntä rekisteröitymistä vaativien palveluidemme
      käyttämiseksi, esimerkkinä FINGENIOUS-palvelut biopankkinäytteitä ja niihin liittyviä tietoja
      koskevien hakemusten tekemiseksi. Se on välttämätöntä myös tiedotteiden tilaamiseen ja
      tapahtumiin osallistumiseen.
    </p>

    <h2>Evästeet Fingenious.fi-sivustolla</h2>
    <p>
      Käytämme evästeitä sisällön ja mainosten personointiin, sosiaalisen median ominaisuuksien
      tarjoamiseen ja liikenteen analysointiin. Käytämme evästeiden antamia tietoja esimerkiksi
      mainosten kohdistamiseen sosiaalisen median alustoissa ja Google Adsissa. Käytämme
      evästetietoja myös Google Analyticsissa ja Google Tag Managerissa. Jaamme myös tietoja
      sivustomme käytöstäsi sosiaalisen median, mainonta- ja analytiikkakumppaneidemme kanssa, jotka
      voivat yhdistää ne muihin tietoihin, jotka olet heille antanut tai joita he ovat keränneet
      käyttäessäsi palveluitaan.
    </p>
    <p>
      Vierailijat voivat käyttää Cookiebot-palvelua evästeiden hallintaan verkkosivustolla. Voit
      hallita evästeasetuksia helposti napsauttamalla pyöreää kuvaketta vasemmassa alakulmassa.
      Löydät lisätietoa siitä, mitä tietoja evästeasetusten avulla kerätään, vasemmassa alakulmassa
      olevasta kuvakkeesta.
    </p>

    <h2>Keräämämme henkilötiedot</h2>

    <p>Keräämme seuraavan tyyppisiä henkilötietoja:</p>

    <h4>Verkkopalveluiden rekisteröinnin ja käytön yhteydessä voimme kerätä seuraavia tietoja</h4>
    <BulletList>
      <li>Nimi ja arvonimi</li>
      <li>Sähköpostiosoite</li>
      <li>Osoite</li>
      <li>Puhelinnumero</li>
      <li>
        Edustamasi organisaatio (hakijaorganisaatio) ja sen tyyppi (esim. akateeminen, teollisuus)
      </li>
      <li>Valittu kielivaihtoehto</li>
      <li>IP-osoite</li>
    </BulletList>
    <h4>Hakemuslomakkeissa voimme myös kerätä</h4>
    <BulletList>
      <li>utkimusprojektiin osallistuvien tutkijoiden nimet ja organisaatiot</li>
      <li>
        Tutkimushankkeesta vastuussa olevien ja sopimusten allekirjoittamiseen valtuutettujen
        henkilöiden nimet, osoitteet, sähköpostiosoitteet ja edustettuina olevat organisaatiot.
      </li>
    </BulletList>
    <h4>Saatamme kerätä uutiskirjetilauksia Contentful-pilvipalvelussa</h4>
    <BulletList>
      <li>Nimi ja arvonimi</li>
      <li>Sähköpostiosoite</li>
      <li>Puhelinnumero</li>
      <li>Edustettu organisaatio</li>
      <li>Tilaajan vapaaehtoiset kommentit</li>
      <li>Suostumustiedot</li>
    </BulletList>

    <h4>Saatamme kerätä tapahtumia varten</h4>
    <BulletList>
      <li>Nimi ja arvonimi</li>
      <li>Sähköpostiosoite</li>
      <li>Puhelinnumero</li>
      <li>Edustettu organisaatio</li>
      <li>Ruokavalioasetukset</li>
      <li>Tapahtumavalokuvat ja videotallenteet</li>
      <li>Tiedot suostumuksesta</li>
    </BulletList>

    <h4>
      Asiantuntijahakemistoa varten voimme kerätä yllä lueteltujen online-palveluiden rekisteröinti-
      ja käyttötietojen lisäksi
    </h4>
    <BulletList>
      <li>Vapaamuotoiset asiantuntemuksen avainsanat</li>
      <li>Erikoisalat</li>
      <li>Tutkimusosaaminen</li>
      <li>Työrooli</li>
      <li>Tutkimusvaiheet</li>
      <li>Tautikohtainen osaaminen (ICD10)</li>
      <li>Harvinaisten sairauksien asiantuntemus</li>
    </BulletList>

    <h2>Henkilötietojen vastaanottajat</h2>
    <p>
      Jaamme jättämäsi hakemukset ja niihin sisältyvät henkilötiedot vain biopankkitoiminnan
      harjoittajille, rekisterinpitäjille ja viranomaisille, joille hakemukset on osoitettu.{' '}
    </p>
    <p>
      Voimme käyttää ATK- ja muita alihankkijoita (käsittelijöitä) palveluidemme tarjoamiseen ja
      henkilötietojen hallintaan. Jotkut näistä alihankkijoista voivat sijaita Euroopan unionin tai
      Euroopan talousalueen ulkopuolella maissa, joissa Euroopan tietosuojalakeja ei sovelleta.
      Tällaisissa tapauksissa riittävä tietoturva ja -suojaus varmistetaan sopimuksilla EU:n
      komission hyväksymin ehdoin ja muilla asianmukaisilla teknisillä ja organisatorisilla
      keinoilla.
    </p>

    <h2>Henkilötietojen säilytys</h2>
    <p>
      Säilytämme henkilötietoja niin kauan kuin niitä tarvitaan siihen tarkoitukseen, jota varten ne
      on kerätty.{' '}
    </p>

    <h2>Sinun oikeutesi</h2>
    <p>Sinulla on GDPR:n mukaiset oikeudet:</p>
    <BulletList>
      <li>oikeus peruuttaa suostumuksesi</li>
      <li>oikeus saada tietoa käsittelystä</li>
      <li>oikeus tutustua henkilötietoihisi</li>
      <li>oikeus henkilötietojen oikaisemiseen</li>
      <li>oikeus vaatia henkilötietojen poistamista</li>
      <li>oikeus rajoittaa henkilötietojen käsittelyä</li>
      <li>oikeus tietojen siirtämiseen</li>
      <li>oikeus vastustaa henkilötietojen käsittelyä</li>
      <li>oikeus tehdä valitus valvontaviranomaiselle</li>
    </BulletList>
    <h4>Suomen valvontaviranomainen on:</h4>
    <p>
      Tietosuojavaltuutetun toimisto
      <br />
      Käyntiosoite: Ratapihantie 9, 00520 Helsinki
      <br />
      Postiosoite: P.O. Box 800, 00521 Helsinki
      <br />
      <a href="mailto:tietosuoja@om.fi">tietosuoja@om.fi.</a>.
    </p>

    <p>Tätä tietosuojailmoitusta voidaan päivittää ajoittain.</p>
  </>
);

const PrivacyPolicyPage = () => {
  const { i18n } = useTranslation();

  const lang = useMemo(() => i18n.language, [i18n.language]);

  return (
    <div className="finbb-container">
      {lang === 'en' ? <PrivacyPolicyEnglish /> : <PrivacyPolicyFinnish />}
    </div>
  );
};

export default memo(PrivacyPolicyPage);
