import { KeywordType } from './Keyword.types';

export interface UpsertExpertProfileDto {
  keywords?: string[];
  researchPhases?: string[];
  researchExpertises?: string[];
  roles?: string[];
  therapyAreas?: string[];
  icd10Codes?: string[];
  rareNeurologicalDiseases?: string[];
  enabled?: boolean;
}

export enum ExportProfileCategory {
  ResearchPhases = 'researchPhases',
  ResearchExpertises = 'ResearchExpertises',
  Roles = 'roles',
  TherapyAreas = 'therapyAreas',
  Icd10Codes = 'icd10Codes',
  RareNeurologicalDiseases = 'rareNeurologicalDiseases',
}

export type ExportProfileCategoryResponseType = {
  id: string;
  createdAt: string;
};

export type ExportProfileCategoryType = {
  id: string;
  name: string;
};

export type ExpertProfileKeywordType = Omit<KeywordType, 'id'> & { keywordId: string };

export interface ExpertProfileDto {
  id: string;
  enabled: boolean;
  keywords: ExpertProfileKeywordType[];
  researchPhases: ExportProfileCategoryResponseType[];
  researchExpertises: ExportProfileCategoryResponseType[];
  roles: ExportProfileCategoryResponseType[];
  therapyAreas: ExportProfileCategoryResponseType[];
  icd10Codes: ExportProfileCategoryResponseType[];
  rareNeurologicalDiseases: ExportProfileCategoryResponseType[];
  createdAt: string;
  updatedAt: string;
}
