import { Table } from '@finbb/ui-components';
import { memo, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Column } from 'react-table';
import { StateContext } from '../../context/StateProvider/StateProvider';
import useEndpoint from '../../hooks/useEndpoint';
import { OrganisationApi } from '../../routes/ApiDefinitions';
import { Organisations } from '../../routes/RouteDefinitions';
import { OrganisationDto, OrganisationTypeType } from '../../types/Organisation.types';
import EmptyValue from '../EmptyValue/EmptyValue';
import { StyledBadge } from './OrganisationList.styles';
import { OrganisationListDataType } from './OrganisationList.types';
import SearchInput from '../SearchInput/SearchInput';

const OrganisationList = () => {
  const { t } = useTranslation();
  const { state } = useContext(StateContext);
  const [originalData, setOriginalData] = useState([] as OrganisationListDataType[]);
  const [listData, setListData] = useState([] as OrganisationListDataType[]);

  const [{ data: organisations }] = useEndpoint<OrganisationDto[]>(
    {
      method: 'GET',
      options: { blockRenderingWhileLoading: true, callImmediately: true },
      url: OrganisationApi.path,
    },
    []
  );

  useEffect(() => {
    const data: OrganisationListDataType[] = organisations.map((organisation) => ({
      code: organisation.code,
      id: organisation.id,
      type: organisation.organisationType,
      name: organisation.name,
      countryCode: organisation.countryCode,
      category: organisation.organisationCategory,
    }));
    setListData(data);
    setOriginalData(data);
  }, [organisations]);

  const canUpdateAnyOrganisation = state.permissions?.includes('update:all-organisations');

  const columns: Column<OrganisationListDataType>[] = [
    {
      Header: t('Labels.code'),
      Cell: ({ row, value }) =>
        canUpdateAnyOrganisation ? (
          <Link to={`${Organisations.path}/${row.original.id}`}>{value}</Link>
        ) : (
          value
        ),
      accessor: 'code',
    },
    {
      Header: t('Labels.name'),
      Cell: ({ row, value }) => (
        <>
          {canUpdateAnyOrganisation ? (
            <Link to={`${Organisations.path}/${row.original.id}`}>{value}</Link>
          ) : (
            value
          )}
          <StyledBadge>
            {t(`OrganisationTypes.${row.original.type as OrganisationTypeType}`)}
          </StyledBadge>
        </>
      ),
      accessor: 'name',
    },
    {
      Header: t('Labels.organisationCategory'),
      accessor: 'category',
    },
    {
      Header: t('Labels.countryCode'),
      accessor: 'countryCode',
    },
  ];

  return (
    <>
      <SearchInput
        data={originalData}
        callback={(filteredData) => setListData(filteredData as OrganisationListDataType[])}
      />
      <Table
        allowSorting
        columns={columns}
        data={listData}
        emptyValueIndicator={<EmptyValue />}
        noDataMessage={t('Labels.noData')}
        pageSelectorLabel={t('Actions.goToPage')}
      />
    </>
  );
};

export default memo(OrganisationList);
