export const adminOrganisationRoles = [
  'finbb-admin',
  'finbb-coordinator',
  'finbb-reporter',
  'related-biobank-steering-group',
] as const;

export const genericRoles = [
  'organisation-coordinator',
  'organisation-editor',
  'organisation-reporter',
  'organisation-steering-group',
  'ethical-reviewer',
  'researcher',
  'expert',
] as const;

export const userRoles = [...adminOrganisationRoles, ...genericRoles];

export type AdminOrganisationRoleType = (typeof adminOrganisationRoles)[number];
export type GenericRoleType = (typeof genericRoles)[number];
export type RoleType = AdminOrganisationRoleType | GenericRoleType;

export interface AddRoleDto {
  roles: RoleType[];
}

export type UserRoleType = (typeof userRoles)[number];
export type UserRoleObjectType = { [K in UserRoleType]: K };
export const userRole: UserRoleObjectType = userRoles.reduce(
  (roles, roleName: UserRoleType) => ({
    ...roles,
    [roleName]: roleName,
  }),
  {} as UserRoleObjectType
);
