import { memo } from 'react';
import { StyledEmptyValue } from './EmptyValue.styles';

const EmptyValue = () => {
  const glyph = '–';

  return <StyledEmptyValue>{glyph}</StyledEmptyValue>;
};

export default memo(EmptyValue);
