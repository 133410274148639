import { NavigationRouteType } from '../types/Route.types';
import {
  ExpertProfiles,
  Organisations,
  OwnOrganisation,
  Projects,
  Users,
} from './RouteDefinitions';

const NavigationDefinitions: NavigationRouteType[] = [
  Organisations,
  OwnOrganisation,
  Projects,
  Users,
  ExpertProfiles,
];

export default NavigationDefinitions;
