import { Input } from '@finbb/ui-components';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useDebouncedEffect from '../../hooks/useDebounceEffect';
import { ListSearchInputType, ObjectType } from './SearchInput.types';

const MIN_SEARCH_QUERY_LENGTH = 2;
const DELAY = 333;

const SearchInput = <T extends ObjectType>({
  data,
  filterableFields,
  callback,
  initialSearchQuery,
}: ListSearchInputType<T>) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState<string>('');

  const isFilterable = (key: keyof T, object: T) => {
    // only filter by the fields defined in filterableFields or,
    // if it's not defined, any field that has a string as a value
    const valueIsString = typeof object[key] === 'string';
    if (filterableFields && valueIsString) {
      return filterableFields.includes(key as string);
    }
    return valueIsString;
  };

  const filterData = (object: T) =>
    !!Object.keys(object).find((key: string) => {
      if (isFilterable(key as keyof T, object)) {
        // isFilterable checks the value type but TypeScript is not
        // smart enough to notice that so we have to use "as string" here
        return (object[key as keyof T] as string).toLowerCase().includes(searchQuery.toLowerCase());
      }
      return false;
    });

  const filterWithSearchQuery = () => {
    // If we have no data do nothing
    if (!data.length) {
      return;
    }

    // No need to filter if we have no search query or it's too short
    if (searchQuery.length <= MIN_SEARCH_QUERY_LENGTH) {
      callback(data, searchQuery);
    }

    // If we have data then filter it
    callback(data.filter(filterData), searchQuery);
  };

  useDebouncedEffect(() => filterWithSearchQuery(), [data, searchQuery], DELAY);

  useEffect(() => {
    setSearchQuery(initialSearchQuery ?? '');
  }, [data]);

  const onChange = (event: React.FormEvent<HTMLInputElement>) => {
    const search = event.currentTarget.value;
    setSearchQuery(search);
  };

  return (
    <Input
      value={searchQuery}
      variant="NORMAL"
      message=""
      onChange={onChange}
      placeholder={t('Placeholders.search')}
    />
  );
};

export default memo(SearchInput) as typeof SearchInput;
