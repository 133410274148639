import { RouteType } from '../types/Route.types';

const basePath = '/api';

export const OrganisationApi: RouteType = {
  path: `${basePath}/organisation`,
};

export const CollaborationOrganisationApi: RouteType = {
  path: `${basePath}/collaboration-organisation`,
};
export const ProjectApi: RouteType = {
  path: `${basePath}/project`,
};

export const UserApi: RouteType = {
  path: `${basePath}/user`,
};

export const ExpertProfileApi: RouteType = {
  path: `${basePath}/expert-profiles`,
};

export const ExpertProfileCategoriesApi: RouteType = {
  path: `${basePath}/expert-profile-categories`,
};

export const KeywordApi: RouteType = {
  path: `${basePath}/keyword`,
};
