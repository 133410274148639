import { Form } from 'formik';
import styled from 'styled-components';

export const StyledHeading = styled.p`
  ${({ theme }) => theme.objects.Heading.Scale.MEDIUM}
  ${({ theme }) => theme.objects.Heading.Style.Primary}
`;

export const StyledFieldSet = styled.fieldset`
  ${({ theme }) => theme.objects.Fieldset.Base}
`;

export const StyledProjectForm = styled(Form)`
  ${({ theme }) => theme.mixins.Container('COLUMN')}
`;
