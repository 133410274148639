import { ButtonType } from '@finbb/ui-components';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Root } from '../../routes/RouteDefinitions';
import ActionableMessage from '../ActionableMessage/ActionableMessage';

const SignupSuccess = () => {
  const { t } = useTranslation();
  const history = useNavigate();
  const buttons: ButtonType[] = [
    {
      text: t('Actions.toHome'),
      onClick: () => history(Root.path),
      variant: 'CALL_TO_ACTION',
    },
  ];
  return (
    <ActionableMessage
      heading={t('Headings.signupSuccess')}
      message={t('Messages.signupSuccess')}
      buttons={buttons}
    />
  );
};

export default memo(SignupSuccess);
