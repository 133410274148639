import { memo } from 'react';
import { StyledCenteredParagraph } from './CenteredParagraph.styles';

import { CenteredParagraphType } from './CenteredParagraph.types';

const CenteredParagraph = ({ text, link, openInNewTab }: CenteredParagraphType) => {
  const target = openInNewTab ? '_blank' : '_self';
  return (
    <StyledCenteredParagraph>
      <a target={target} href={link}>
        {text}
      </a>
    </StyledCenteredParagraph>
  );
};

export default memo(CenteredParagraph);
