import i18n from '../i18n/i18n';
import { OrganisationDto } from '../types/Organisation.types';
import { ProjectUserType, UserDto } from '../types/User.types';

export const formatOrganisationLocation = (organisation: OrganisationDto): string =>
  `${organisation.zip}, ${organisation.city} ${organisation.countryCode}`;

export const formatUserName = (user?: UserDto | ProjectUserType): string =>
  user ? `${user.firstName} ${user.lastName}` : i18n.t('Errors.userNotFound');

export const isFormikPropertyChanged = <T>(
  property: keyof T,
  currentValues: T,
  initialValues: T
): boolean => {
  const isChanged = currentValues[property] !== initialValues[property];

  return isChanged;
};

export const getChangedFormikValue = <T>(
  property: keyof T,
  currentValues: T,
  initialValues: T
): Record<string, T[keyof T]> | null => {
  const isChanged = isFormikPropertyChanged(property, currentValues, initialValues);

  if (!isChanged) {
    return null;
  }

  const changedValue = {
    [property]: currentValues[property],
  };

  return changedValue;
};

/**
 * Checks how similar 2 given strings are
 *
 * @see https://stackoverflow.com/questions/10473745/compare-strings-javascript-return-of-likely
 */
export const stringSimilarity = (compareTerm1: string, compareTerm2: string) => {
  const longer = compareTerm1.length < compareTerm2.length ? compareTerm2 : compareTerm1;
  const shorter = compareTerm1.length < compareTerm2.length ? compareTerm1 : compareTerm2;
  const longerLength = longer.length;

  if (longerLength === 0) {
    return 1.0;
  }

  const editDistance = (term1: string, term2: string) => {
    const costs = [];
    for (let i = 0; i <= term1.length; i += 1) {
      let lastValue = i;

      for (let j = 0; j <= term2.length; j += 1) {
        if (i === 0) {
          costs[j] = j;
        } else if (j > 0) {
          let newValue = costs[j - 1];

          if (term1.charAt(i - 1) !== term2.charAt(j - 1)) {
            newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
          }

          costs[j - 1] = lastValue;
          lastValue = newValue;
        }
      }

      if (i > 0) {
        costs[term2.length] = lastValue;
      }
    }

    return costs[term2.length];
  };

  return (
    (longerLength - editDistance(longer.toLowerCase(), shorter.toLowerCase())) /
    parseFloat(longerLength.toString())
  );
};

export const filterSimilarStrings = <T>({
  filterWith = '',
  filterFrom = [],
  similarityThreshold = 0.095,
  getFilterValue,
  sortBySimilarity,
}: {
  filterWith: string;
  filterFrom: T[];
  similarityThreshold?: number;
  getFilterValue: (entry: T) => string;
  sortBySimilarity?: boolean;
}) => {
  if (!filterWith?.length || !filterFrom.length) {
    return filterFrom;
  }

  if (!sortBySimilarity) {
    return filterFrom.filter((entry: T) => {
      const comp1 = getFilterValue(entry).toLowerCase();
      const comp2 = filterWith.toLowerCase();

      return comp1.includes(comp2) || stringSimilarity(comp1, comp2) >= similarityThreshold;
    });
  }

  return filterFrom
    .map((entry) => {
      const comp1 = getFilterValue(entry).toLowerCase();
      const comp2 = filterWith.toLowerCase();
      const similarity = (comp1.includes(comp2) ? 1 : 0) + stringSimilarity(comp1, comp2);

      return { value: entry, similarity };
    })
    .filter((entry: { value: T; similarity: number }) => entry.similarity >= similarityThreshold)
    .sort((a, b) => b.similarity - a.similarity)
    .map((entry) => entry.value);
};
