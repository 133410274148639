import { Form } from 'formik';
import styled from 'styled-components';
import { StyledBadgeType } from './UserInfoForm.types';

export const StyledBadge = styled.p<StyledBadgeType>`
  ${({ theme }) => theme.objects.Badge.Base}
  margin: auto 0;
`;

export const StyledStatusMessage = styled.p`
  ${({ theme }) => theme.objects.Copy.Subtle}
  margin: 0;
`;

export const StyledSuccessMessage = styled(StyledStatusMessage)`
  color: ${({ theme }) => theme.values.colors.text.success.BASE};
`;

export const StyledErrorMessage = styled(StyledStatusMessage)`
  color: ${({ theme }) => theme.values.colors.text.caution.BASE};
`;

export const StyledButtonRow = styled.div`
  ${({ theme }) => theme.mixins.Container('ROW', { alignItems: 'center' })}
`;

export const StyledHeading = styled.p`
  ${({ theme }) => theme.objects.Heading.Scale.MEDIUM}
  ${({ theme }) => theme.objects.Heading.Style.Primary}
`;

export const StyledRow = styled.div`
  ${({ theme }) => theme.mixins.Container('ROW', { justifyContent: 'space-between' })}
`;

export const StyledFieldSet = styled.fieldset`
  ${({ theme }) => theme.objects.Fieldset.Base}
`;

export const StyledUserInfoForm = styled(Form)`
  ${({ theme }) => theme.mixins.Container('COLUMN')}
`;
