import { Button } from '@finbb/ui-components';
import { memo, useContext } from 'react';
import { StateContext } from '../../context/StateProvider/StateProvider';
import { StyledCloseIcon, StyledErrorMessage, StyledErrorText } from './ErrorMessage.styles';

const CloseIcon = () => (
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 80 352 512"
    className="svg-inline--fa fa-times fa-w-11 fa-1x"
  >
    <path
      fill="currentColor"
      // eslint-disable-next-line
      d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
      className=""
    />
  </svg>
);

const ErrorMessage = () => {
  const { state, dispatch } = useContext(StateContext);

  const handleClose = () => {
    dispatch({ type: 'SET_ERROR_MESSAGE', payload: null });
  };

  if (!state.errorMessage) {
    return null;
  }

  return (
    <StyledErrorMessage>
      <StyledErrorText>{state.errorMessage}</StyledErrorText>
      <Button
        type="button"
        onClick={handleClose}
        variant="UNOBTRUSIVE"
        icon={
          <StyledCloseIcon>
            <CloseIcon />
          </StyledCloseIcon>
        }
      />
    </StyledErrorMessage>
  );
};

export default memo(ErrorMessage);
