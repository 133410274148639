import { memo, useContext, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { StateContext } from '../../context/StateProvider/StateProvider';
import { OwnUser } from '../../routes/RouteDefinitions';
import ExpertProfileList from '../../components/ExpertProfileList/ExpertProfileList';
import ExpertProfilePage from '../../components/ExpertProfilePage/ExpertProfilePage';

const ExpertProfileManagementPage = () => {
  const history = useNavigate();
  const { state } = useContext(StateContext);

  useEffect(() => {
    if (state.permissions && state.permissions.length > 0) {
      if (!state.permissions?.includes('read:all-expert-profiles')) {
        history(OwnUser.path);
      }
    }
  }, [history, state.permissions]);

  return (
    <Routes>
      <Route path="" element={<ExpertProfileList />} />
      <Route path=":id" element={<ExpertProfilePage />} />
    </Routes>
  );
};

export default memo(ExpertProfileManagementPage);
