import { TranslationType } from '../i18n.types';

const finnish: TranslationType = {
  AccountStates: {
    accepted: 'Hyväksytty',
    pending: 'Odottaa hyväksyntää',
    rejected: 'Hylätty',
  },
  Actions: {
    addNewOrganisation: 'Lisää uusi organisaatio',
    addNewProject: 'Lisää uusi projekti',
    approveUser: 'Hyväksy käyttäjä',
    archiveProject: 'Arkistoi projekti',
    changePassword: 'Vaihda salasana',
    createNewKeyword: 'Luo "{{ keyword }}"',
    deleteAccount: 'Poista tili',
    goToPage: 'Siirry sivulle',
    login: 'Kirjaudu sisään',
    logout: 'Kirjaudu ulos',
    rejectUser: 'Hylkää käyttäjä',
    save: 'Tallenna',
    showDeleteSomethingButton: 'Voit poistaa $t({{ something }}) lopullisesti painamalla tästä.',
    signup: 'Rekisteröidy',
    submit: 'Lähetä',
    toHome: 'Siirry etusivulle',
    unarchiveProject: 'Palauta arkistosta',
    giveConsent: 'Hyväksy',
    clear: 'Tyhjennä',
  },
  ApplicationNames: {
    cohorts: 'Kohortit',
    platform: 'Alusta',
    requests: 'Pyynnöt',
    recruit: 'Rekry',
    offertool: 'Tarjoustyökalu',
    researcherUi: 'Tutkijan käyttöliittymä',
  },
  Common: {
    applicationName: 'Alusta',
  },
  Errors: {
    organisationNotFound: 'Organisaatiota ei löytynyt',
    projectNotFound: 'Projektia ei löytynyt',
    userNotFound: 'Käyttäjää ei löytynyt',
    saveFailed: 'Tallennus epäonnistui, yritä uudelleen',
    signUpFailed: 'Rekisteröinti epäonnistui, yritä uudelleen',
  },
  Headings: {
    addOrganisation: 'Uuden organisaation tiedot',
    addProject: 'Uuden projektin tiedot',
    expertProfile: 'Asiantuntijaprofiili',
    organisationInformation: 'Organisaation tiedot',
    organisationUsers: 'Organisaation käyttäjät',
    projectInformation: 'Projektin tiedot',
    signupPending: 'Tilisi odottaa hyväksymistä',
    signupSuccess: 'Kiitos rekisteröitymisestä!',
    userInformation: 'Omat tiedot',
    welcome: 'Tervetuloa!',
  },
  Labels: {
    automaticallyGenerated: 'Generoidaan automaattisesti',
    address: 'Osoite',
    city: 'Kaupunki',
    code: 'Lyhenne',
    confirmPassword: 'Vahvista salasana',
    countryCode: 'Maakoodi',
    description: 'Kuvaus',
    email: 'Sähköposti',
    emailCaution:
      'Käytä rekisteröitymisessä työosoitettasi, henkilökohtaisia osoitteita ei hyväksytä (kuten esimerkiksi gmail.com, live.com, foxmail.com, 163.com, qq.com, 126.com).',
    firstName: 'Etunimi',
    id: 'Tunniste',
    lastName: 'Sukunimi',
    name: 'Nimi',
    noData: 'Ei näytettäviä tietoja',
    organisation: 'Organisaatio',
    organisationCategory: 'Luokka',
    organisationType: 'Tyyppi',
    ownAccount: 'oman tilisi',
    password: 'Salasana',
    phone: 'Puhelinnumero',
    phoneIntlFormat: 'Puhelinnumero kansainvälisessä muodossa',
    preferredLanguage: 'Kieli',
    projectId: 'Projektin tunniste',
    projectCoordinator: 'Koordinaattori',
    projectResearcher: 'Tutkija',
    select: 'Valitse',
    someoneElsesAccount: 'tämän käyttäjän tilin',
    title: 'Otsikko',
    userRole: 'Roolit',
    zip: 'Postinumero',
    state: 'State',
    keywords: 'Avainsanat',
    showKeywords: 'Näytä avainsanat',
    relatedBiobank: 'Sivuorganisaatio biopankki',
    prefilteredResults: 'Varoitus: Lista on esisuodatettu annetuilla hakuparametreilla.',
    collaborationOrganisation: 'Yhteistyöverkosto',
    hasExpertProfile: 'Asiantuntijaprofiili',
    isSideUser: 'Rooli',
    primary: 'Päärooli',
    secondary: 'Sivurooli',
    icd10Codes: 'ICD-10',
    roles: 'Roolit',
    rareNeurologicalDiseases: 'Harvinaiset neurologiset sairaudet',
    researchExpertises: 'Tutkimusasiantuntijuus',
    researchPhases: 'Tutkimusvaiheet',
    therapyAreas: 'Terapiaalueet',
    enabled: 'Käytössä',
    disabled: 'Ei käytössä',
  },
  Languages: {
    en: 'English',
    fi: 'Suomi',
  },
  Messages: {
    confirmOwnAccountDeletion:
      'Tili ei ole poistamisen jälkeen palautettavissa. Haluatko varmasti poistaa tilisi?',
    confirmPasswordChange:
      'Saat pian sähköpostitse linkin, jossa voit vaihtaa salasanasi. Haluatko jatkaa?',
    confirmProjectArchival: 'Haluatko varmasti arkistoida projektin?',
    confirmProjectUnarchival: 'Haluatko varmasti palauttaa projektin arkistosta?',
    confirmUserReject: 'Haluatko varmasti hylätä tämän käyttäjän rekisteröitymisen?',
    signupPending: 'Voit aloittaa Fingenious-sovellusten käyttämisen, kun tilisi on hyväksytty.',
    signupSuccess:
      'Rekisteröitymisesi on nyt käsiteltävänä. Saat sähköpostiisi viestin, kun FINBB:n henkilökunta on hyväksynyt sinut käyttäjäksi.',
    informationSaved: 'Tiedot tallennettu!',
    welcome: 'Tervetuloa käyttämään Fingeniousin alustapalvelua.',
    expertProfileConsent:
      'Jos haluat osallistua asiantuntijana biopankkitutkimusprojekteihin, aloita asiantuntijaprofiilisi täyttäminen painamalla alla olevaa hyväksy-painiketta.',
    expertProfileNotYourOwnConsentNotGiven:
      'Huom. Henkilö ei ole vielä antanut suostumusta asiantuntijaprofiilin luomiseen.',
  },
  OrganisationCategories: {
    'admin-organisation': 'Ylläpitäjä',
    'collaboration-organisation': 'Yhteistyöorganisaatio',
    customer: 'Asiakasorganisaatio',
    service: 'Palveluorganisaatio',
  },
  OrganisationTypes: {
    academic: 'Akateeminen',
    industry: 'Yritys',
    other: 'Muu',
  },
  Placeholders: {
    search: 'Etsi',
  },
  ProjectStates: {
    archived: 'Arkistoitu',
    active: 'Käynnissä',
  },
  Roles: {
    'finbb-admin': 'FINBB Ylläpitäjä',
    'finbb-coordinator': 'FINBB Koordinaattori',
    'finbb-reporter': 'FINBB Raportoija',
    'organisation-coordinator': 'Koordinaattori',
    'organisation-editor': 'Käsittelijä',
    'organisation-reporter': 'Raportoija',
    'organisation-steering-group': 'Ohjausryhmän jäsen',
    'ethical-reviewer': 'Eettisen lautakunnan jäsen',
    'related-biobank-steering-group': 'Ohjausryhmän jäsen sivurooli',
    expert: 'Asiantuntija',
    researcher: 'Researcher',
  },
  Routes: {
    account: 'Tili',
    projects: 'Projektit',
    organisations: 'Organisaatiot',
    ownOrganisation: 'Organisaatio',
    root: 'Etusivu',
    users: 'Käyttäjät',
    signup: 'Rekisteröinti',
    privacyPolicy: 'Tietosuojaseloste',
    expertProfiles: 'Asiantuntijaprofiilit',
  },
  ValidationErrors: {
    countryCode: 'Virheellinen maakoodi. Koodin pitää olla standardin ISO 3166-1 alpha-2 mukainen.',
    email: 'Virheellinen sähköpostiosoite',
    invalidPhoneNumber: 'Virheellinen puhelinnumero',
    required: 'Pakollinen kenttä',
    passwordMustHaveLowercase: 'Salasanan on sisällettävä vähintään yksi pieni kirjain',
    passwordMustHaveUppercase: 'Salasanan on sisällettävä vähintään yksi iso kirjain',
    passwordMustHaveNumber: 'Salasanan on sisällettävä vähintään yksi numero',
    passwordMustHaveSpecialChar: 'Salasanan on sisällettävä vähintään yksi erikoismerkki',
    passwordsMustMatch: 'Salasanat eivät ole samat',
    stringTooLong: 'Liian pitkä',
    stringTooShort: 'Liian lyhyt',
  },
};

export default finnish;
