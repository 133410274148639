export const organisationTypes = ['academic', 'industry', 'other'] as const;
export type OrganisationTypeType = (typeof organisationTypes)[number];

export const organisationCategories = [
  'admin-organisation',
  'collaboration-organisation',
  'customer',
  'service',
] as const;
export type OrganisationCategoryType = (typeof organisationCategories)[number];

export interface BaseOrganisationDto {
  address: string;
  city: string;
  countryCode: string;
  name: string;
  organisationType: OrganisationTypeType;
  zip: string;
}

export interface CreateOrganisationDto extends BaseOrganisationDto {
  organisationCategory: OrganisationCategoryType;
  code: string;
}
export type UpdateOrganisationDto = Partial<CreateOrganisationDto>;

export interface OrganisationDto extends BaseOrganisationDto {
  id: string;
  organisationCategory: OrganisationCategoryType;
  code: string;
}

export interface AddToOrganisationDto {
  organisationId: string;
}
