import { useAuth0 } from '@auth0/auth0-react';
import { Button, LinkButton } from '@finbb/ui-components';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { LogoutButtonType } from './LogoutButton.types';

const LogoutButton = ({ variant }: LogoutButtonType) => {
  const { logout } = useAuth0();
  const { t } = useTranslation();

  const handleClick = () => logout({ logoutParams: { returnTo: window.location.origin } });

  if (variant === 'LINK') {
    return (
      <LinkButton onClick={handleClick} to="#" text={t('Actions.logout')} variant="UNOBTRUSIVE" />
    );
  }

  return <Button data-testid="LogoutButton" onClick={handleClick} text={t('Actions.logout')} />;
};

export default memo(LogoutButton);
