import { memo } from 'react';
import { Helmet } from 'react-helmet';
import cryptoRandomString from 'crypto-random-string';

const CspHeader = () => {
  const API_ADDRESS = process.env.REACT_APP_API_DOMAIN;
  const AUTH_SERVICE = process.env.REACT_APP_AUTH0_DOMAIN;

  const createCspString = (nonce: string) => {
    const cspRules = {
      'default-src': ["'self'"],
      'style-src': ["'self'"],
      'worker-src': ["'self'", 'blob:'],
      'frame-src': [AUTH_SERVICE],
      'connect-src': ["'self'", AUTH_SERVICE, API_ADDRESS],
    };

    cspRules['style-src'].push(`'nonce-${nonce}'`);

    const ruleStrings = Object.entries(cspRules).map((cspRule) => {
      const ruleName = cspRule[0];
      const rules = cspRule[1];
      return `${ruleName} ${rules.join(' ')}`;
    });

    return ruleStrings.join(';');
  };

  const nonce = cryptoRandomString({ length: 16, type: 'url-safe' });
  // eslint-disable-next-line no-underscore-dangle
  window.__webpack_nonce__ = nonce;
  const cspString = createCspString(nonce);

  return (
    <Helmet>
      <meta httpEquiv="Content-Security-Policy" content={cspString} />
    </Helmet>
  );
};

export default memo(CspHeader);
