import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useEndpoint from '../../hooks/useEndpoint';
import { ProjectApi } from '../../routes/ApiDefinitions';
import { ProjectDto } from '../../types/Project.types';
import ProjectForm from '../ProjectForm/ProjectForm';
import { StyledProjectSettings } from './ProjectSettings.styles';
import { RouteParamsType } from './ProjectSettings.types';

const ProjectSettings = () => {
  const { t } = useTranslation();
  const { id } = useParams<RouteParamsType>();

  const [{ data: project }] = useEndpoint<ProjectDto>(
    {
      method: 'GET',
      url: `${ProjectApi.path}/${id}`,
      options: { blockRenderingWhileLoading: true, callImmediately: true },
    },
    {} as ProjectDto
  );

  if (!project.id) {
    return <div>{t('Errors.projectNotFound')}</div>;
  }

  return (
    <StyledProjectSettings>
      <ProjectForm project={project} />
    </StyledProjectSettings>
  );
};

export default memo(ProjectSettings);
