import { TranslationType } from '../i18n.types';

const english: TranslationType = {
  AccountStates: {
    accepted: 'Accepted',
    pending: 'Pending approval',
    rejected: 'Rejected',
  },
  Actions: {
    addNewOrganisation: 'Add a new organisation',
    addNewProject: 'Add a new project',
    approveUser: 'Approve user',
    archiveProject: 'Archive project',
    changePassword: 'Change password',
    createNewKeyword: 'Create "{{ keyword }}"',
    deleteAccount: 'Delete account',
    goToPage: 'Go to page',
    login: 'Login',
    logout: 'Logout',
    rejectUser: 'Reject user',
    save: 'Save changes',
    showDeleteSomethingButton: 'To permanently delete $t({{ something }}), click here.',
    signup: 'Sign up',
    submit: 'Submit',
    toHome: 'Go to home page',
    unarchiveProject: 'Unarchive project',
    clear: 'Clear',
    giveConsent: 'Accept',
  },
  ApplicationNames: {
    cohorts: 'Cohorts',
    platform: 'Platform',
    requests: 'Requests',
    recruit: 'Recruit',
    offertool: 'Offertool',
    researcherUi: 'Researcher UI',
  },
  Common: {
    applicationName: 'Platform',
  },
  Errors: {
    organisationNotFound: 'Organisation not found',
    projectNotFound: 'Project not found',
    userNotFound: 'User not found',
    saveFailed: 'Save failed, please try again',
    signUpFailed: 'Registration failed, please try again',
  },
  Headings: {
    addOrganisation: 'Add a new organisation',
    addProject: 'Add a new project',
    expertProfile: 'Expert profile',
    organisationInformation: 'Organisation information',
    organisationUsers: 'Organisation users',
    projectInformation: 'Project information',
    signupPending: 'Account is pending approval',
    signupSuccess: 'Thanks for signing up!',
    userInformation: 'Account information',
    welcome: 'Welcome!',
  },
  Labels: {
    automaticallyGenerated: 'Will be generated automatically',
    address: 'Address',
    city: 'City',
    code: 'Abbreviation',
    confirmPassword: 'Confirm password',
    countryCode: 'Country code',
    description: 'Description',
    email: 'Email',
    emailCaution:
      'Please use your institutional email address for registering. Personal email addresses are NOT accepted (e.g. gmail.com, live.com, foxmail.com, 163.com, qq.com, 126.com).',
    firstName: 'Given name',
    id: 'Identifier',
    lastName: 'Family name',
    name: 'Name',
    noData: 'No data to display',
    organisation: 'Organisation',
    organisationCategory: 'Category',
    organisationType: 'Type',
    ownAccount: 'your own account',
    password: 'Password',
    phone: 'Phone number',
    phoneIntlFormat: 'Phone number in international format',
    preferredLanguage: 'Language',
    projectId: 'Project code',
    projectCoordinator: 'Coordinator',
    projectResearcher: 'Researcher',
    select: 'Select',
    someoneElsesAccount: "this user's account",
    title: 'Title',
    userRole: 'Roles',
    zip: 'Zip code',
    state: 'State',
    keywords: 'Keywords',
    showKeywords: 'Show keywords',
    relatedBiobank: 'Side organisation biobank',
    prefilteredResults: 'Caution: List is prefiltered by passed query parameters.',
    collaborationOrganisation: 'Collaboration network',
    hasExpertProfile: 'Expert profile',
    isSideUser: 'Role',
    primary: 'Primary',
    secondary: 'Secondary',
    icd10Codes: 'ICD-10',
    rareNeurologicalDiseases: 'Rare neurological diseases',
    roles: 'Roles',
    researchExpertises: 'Research expertises',
    researchPhases: 'Research phases',
    therapyAreas: 'Therapy areas',
    enabled: 'Enabled',
    disabled: 'Disabled',
  },
  Languages: {
    en: 'English',
    fi: 'Suomi',
  },
  Messages: {
    confirmOwnAccountDeletion:
      'Your account cannot be recovered after it has been deleted. Do you really want to delete it?',
    confirmPasswordChange:
      'You will soon receive a link to change your password via email. Do you wish to continue?',
    confirmProjectArchival: 'Are you sure you want to archive the project?',
    confirmProjectUnarchival: 'Are you sure you want to unarchive the project?',
    confirmUserReject: "Are you sure you want to reject this user's registration?",
    signupPending:
      'You can start using Fingenious applications once your account has been approved.',
    signupSuccess:
      'Your signup is now being processed. You will receive an email, when the FINBB staff has accepted your account.',
    informationSaved: 'Information saved succesfully!',
    welcome: 'Welcome to the Fingenious platform application.',
    expertProfileConsent:
      'If you would like to participate as expert into the biobank research projects, please start filling in your expert profile by pressing the accept button below',
    expertProfileNotYourOwnConsentNotGiven:
      'Notice that this person has not consented to participating as expert.',
  },
  OrganisationCategories: {
    'admin-organisation': 'Admin organisation',
    'collaboration-organisation': 'Collaboration organisation',
    customer: 'Customer organisation',
    service: 'Service organisation',
  },
  OrganisationTypes: {
    academic: 'Academic',
    industry: 'Industry',
    other: 'Other',
  },
  Placeholders: {
    search: 'Search',
  },
  ProjectStates: {
    archived: 'Archived',
    active: 'Active',
  },
  Roles: {
    'finbb-admin': 'FINBB Administrator',
    'finbb-coordinator': 'FINBB Coordinator',
    'finbb-reporter': 'FINBB Reporter',
    'organisation-coordinator': 'Coordinator',
    'organisation-editor': 'Editor',
    'organisation-reporter': 'Reporter',
    'organisation-steering-group': 'Steering group member',
    'ethical-reviewer': 'Ethical reviewer',
    researcher: 'Researcher',
    expert: 'Expert',
    'related-biobank-steering-group': 'Steering group member side role',
  },
  Routes: {
    account: 'Account',
    projects: 'Projects',
    organisations: 'Organisations',
    ownOrganisation: 'Organisation',
    root: 'Home',
    users: 'Users',
    signup: 'Sign up',
    privacyPolicy: 'Privacy policy',
    expertProfiles: 'Expert profiles',
  },
  ValidationErrors: {
    countryCode: 'Invalid country code. Must be one of ISO 3166-1 alpha-2 codes.',
    email: 'Invalid email',
    invalidPhoneNumber: 'Invalid phone number',
    required: 'Required',
    passwordMustHaveLowercase: 'Password must include a lowercase letter',
    passwordMustHaveUppercase: 'Password must include an uppercase letter',
    passwordMustHaveNumber: 'Password must include a number',
    passwordMustHaveSpecialChar: 'Password must include a special character',
    passwordsMustMatch: 'Passwords do not match',
    stringTooLong: 'Too long',
    stringTooShort: 'Too short',
  },
};

export default english;
