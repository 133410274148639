import {
  ButtonGroup,
  ButtonType,
  Input,
  Select,
  SelectOptionType,
  Table,
} from '@finbb/ui-components';
import { Formik } from 'formik';
import { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import { Column } from 'react-table';
import { StateContext } from '../../context/StateProvider/StateProvider';
import useEndpoint from '../../hooks/useEndpoint';
import { OrganisationApi, UserApi } from '../../routes/ApiDefinitions';
import { Organisations, OwnOrganisation, Users } from '../../routes/RouteDefinitions';
import {
  CreateOrganisationDto,
  organisationCategories,
  OrganisationCategoryType,
  organisationTypes,
  OrganisationTypeType,
  UpdateOrganisationDto,
} from '../../types/Organisation.types';
import { getChangedFormikValue, formatUserName } from '../../utils/data';
import { OrganisationFormSchema } from './OrganisationForm.schemas';
import {
  StyledFieldRow,
  StyledFieldSet,
  StyledHeading,
  StyledOrganisationForm,
} from './OrganisationForm.styles';
import { OrganisationFormContentType, OrganisationFormType } from './OrganisationForm.types';
import { StyledUserList } from '../UserList/UserList.styles';
import { UserListDataType } from '../UserList/UserList.types';
import EmptyValue from '../EmptyValue/EmptyValue';
import { UserDto } from '../../types/User.types';

const OrganisationForm = ({ organisation }: OrganisationFormType) => {
  const { state } = useContext(StateContext);
  const { t } = useTranslation();
  const history = useNavigate();

  const isUpdatePage = Boolean(organisation?.id);

  const [createResponse, createOrganisation] = useEndpoint<UpdateOrganisationDto>(
    {
      method: 'POST',
      url: OrganisationApi.path,
    },
    {} as UpdateOrganisationDto
  );

  const [patchResponse, patchOrganisation] = useEndpoint<UpdateOrganisationDto>(
    {
      method: 'PATCH',
      url: `${OrganisationApi.path}/${organisation?.id}`,
    },
    {} as UpdateOrganisationDto
  );

  const [{ data: users }] = useEndpoint<UserDto[]>(
    {
      method: 'GET',
      options: { blockRenderingWhileLoading: true, callImmediately: true },
      queryParams: {
        organisationId: organisation?.id,
      },
      url: UserApi.path,
    },
    []
  );

  const [{ data: sideUsers }] = useEndpoint<UserDto[]>(
    {
      method: 'GET',
      options: { blockRenderingWhileLoading: true, callImmediately: true },
      queryParams: {
        sideOrganisationId: organisation?.id,
      },
      url: UserApi.path,
    },
    []
  );

  const initialState: OrganisationFormContentType = {
    address: organisation?.address || '',
    city: organisation?.city || '',
    code: organisation?.code || '',
    countryCode: organisation?.countryCode || '',
    name: organisation?.name || '',
    organisationCategory: organisation?.organisationCategory || 'service',
    organisationType: organisation?.organisationType || 'academic',
    zip: organisation?.zip || '',
  };

  const organisationCategoryOptions: SelectOptionType<OrganisationCategoryType>[] =
    organisationCategories
      .filter((category) => category !== 'admin-organisation')
      .map((category) => ({
        name: t(`OrganisationCategories.${category}`),
        value: category,
      }))
      .sort((a, b) => a.name.localeCompare(b.name));

  const organisationTypeOptions: SelectOptionType<OrganisationTypeType>[] = organisationTypes
    .map((type) => ({
      name: t(`OrganisationTypes.${type}`),
      value: type,
    }))
    .sort((a, b) => a.name.localeCompare(b.name));

  const handleSubmit = async (
    values: OrganisationFormContentType,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    if (isUpdatePage) {
      const submitValues: UpdateOrganisationDto = {
        ...getChangedFormikValue('address', values, initialState),
        ...getChangedFormikValue('city', values, initialState),
        ...getChangedFormikValue('code', values, initialState),
        ...getChangedFormikValue('countryCode', values, initialState),
        ...getChangedFormikValue('name', values, initialState),
        ...getChangedFormikValue('organisationCategory', values, initialState),
        ...getChangedFormikValue('organisationType', values, initialState),
        ...getChangedFormikValue('zip', values, initialState),
      };

      if (patchResponse.status === 'IDLE') {
        await patchOrganisation(submitValues);
        setSubmitting(false);
      }
    } else {
      const submitValues: CreateOrganisationDto = {
        address: values.address,
        city: values.city,
        code: values.code,
        countryCode: values.countryCode,
        name: values.name,
        organisationCategory: values.organisationCategory,
        organisationType: values.organisationType,
        zip: values.zip,
      };

      if (createResponse.status === 'IDLE') {
        await createOrganisation(submitValues);
        setSubmitting(false);
      }
    }

    if (state.permissions?.includes('read:all-organisations')) {
      history(Organisations.path);
    } else {
      history(OwnOrganisation.path);
    }
  };

  const buttons: ButtonType[] = [
    {
      text: t(isUpdatePage ? 'Actions.save' : 'Actions.submit'),
      type: 'submit',
      variant: 'CALL_TO_ACTION',
    },
  ];

  const canUpdateOtherUsers = state.permissions?.some((p) =>
    ['update:all-users', 'update:same-organisation-users'].includes(p)
  );

  const columns: Column<UserListDataType>[] = [
    {
      Header: t('Labels.name'),
      Cell: ({ row, value }) =>
        canUpdateOtherUsers && row.original.organisationId === organisation?.id ? (
          <Link to={`${Users.path}/${row.original.id}`}>{value}</Link>
        ) : (
          value
        ),
      accessor: 'name',
    },
    {
      Header: t('Labels.email'),
      accessor: 'email',
    },
    {
      Header: t('Labels.userRole'),
      Cell: ({ value }) => (value ? value.join(', ') : '-'),
      accessor: 'role',
    },
    {
      Header: t('Labels.phone'),
      accessor: 'phone',
    },
    {
      Header: t('Labels.isSideUser'),
      Cell: ({ value }) =>
        value !== organisation?.id ? t('Labels.secondary') : t('Labels.primary'),
      accessor: 'organisationId',
    },
    {
      Header: t('Labels.hasExpertProfile'),
      Cell: ({ value }) => (value ? '✓' : '✕'),
      accessor: 'expertProfileId',
    },
  ];

  const listData: UserListDataType[] = users
    .concat(sideUsers)
    .map((user) => ({
      email: user.email,
      id: user.id,
      name: formatUserName(user),
      phone: user.phone,
      state: user.state,
      role: user.roles,
      organisationId: user.organisationId,
      expertProfileId: user.expertProfileId,
    }))
    .reduce(
      (acc, user) => {
        if (!acc.ids.has(user.id)) {
          acc.ids.add(user.id);
          acc.data.push(user);
        }

        return acc;
      },
      { ids: new Set<string>(), data: [] as UserListDataType[] }
    ).data;

  return (
    <>
      <Formik
        initialValues={initialState}
        onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
        validationSchema={OrganisationFormSchema}
      >
        {({ errors, handleBlur, handleChange, touched, values }) => (
          <StyledOrganisationForm>
            <StyledHeading>
              {t(isUpdatePage ? 'Headings.organisationInformation' : 'Headings.addOrganisation')}
            </StyledHeading>
            <StyledFieldSet>
              <Input
                label={t('Labels.name')}
                message={errors.name && touched.name ? errors.name : ''}
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                required
                value={values.name}
                variant={errors.name && touched.name ? 'CAUTION' : undefined}
              />
              <Input
                label={t('Labels.code')}
                message={errors.code && touched.code ? errors.code : ''}
                name="code"
                onBlur={handleBlur}
                onChange={handleChange}
                required
                value={values.code}
                variant={errors.code && touched.code ? 'CAUTION' : undefined}
              />
              <Input
                label={t('Labels.address')}
                message={errors.address && touched.address ? errors.address : ''}
                name="address"
                onBlur={handleBlur}
                onChange={handleChange}
                required
                value={values.address}
                variant={errors.address && touched.address ? 'CAUTION' : undefined}
              />
              <StyledFieldRow>
                <Input
                  label={t('Labels.zip')}
                  message={errors.zip && touched.zip ? errors.zip : ''}
                  name="zip"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  value={values.zip}
                  variant={errors.zip && touched.zip ? 'CAUTION' : undefined}
                />
                <Input
                  label={t('Labels.city')}
                  message={errors.city && touched.city ? errors.city : ''}
                  name="city"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  value={values.city}
                  variant={errors.city && touched.city ? 'CAUTION' : undefined}
                />
                <Input
                  label={t('Labels.countryCode')}
                  message={errors.countryCode && touched.countryCode ? errors.countryCode : ''}
                  name="countryCode"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="FI, US, DE, UK, GB ..."
                  required
                  value={values.countryCode}
                  variant={errors.countryCode && touched.countryCode ? 'CAUTION' : undefined}
                />
              </StyledFieldRow>
              <StyledFieldRow>
                <Select
                  label={t('Labels.organisationType')}
                  message={
                    errors.organisationType && touched.organisationType
                      ? errors.organisationType
                      : ''
                  }
                  name="organisationType"
                  onChange={handleChange}
                  options={organisationTypeOptions}
                  placeholder={t('Labels.select')}
                  required
                  value={values.organisationType}
                  variant={
                    errors.organisationType && touched.organisationType ? 'CAUTION' : undefined
                  }
                />
                {state.permissions?.includes('update:all-organisations') && (
                  <Select
                    label={t('Labels.organisationCategory')}
                    message={
                      errors.organisationCategory && touched.organisationCategory
                        ? errors.organisationCategory
                        : undefined
                    }
                    name="organisationCategory"
                    onChange={handleChange}
                    options={organisationCategoryOptions}
                    placeholder={t('Labels.select')}
                    required
                    value={values.organisationCategory}
                    variant={
                      errors.organisationCategory && touched.organisationCategory
                        ? 'CAUTION'
                        : undefined
                    }
                  />
                )}
              </StyledFieldRow>
            </StyledFieldSet>

            <ButtonGroup buttons={buttons} amountOfButtonsOnRight={1} />
          </StyledOrganisationForm>
        )}
      </Formik>

      {isUpdatePage && <p>{t('Headings.organisationUsers')}</p>}
      {isUpdatePage && (
        <StyledUserList>
          <Table
            allowSorting
            columns={columns}
            data={listData}
            emptyValueIndicator={<EmptyValue />}
            noDataMessage={t('Labels.noData')}
            pageSelectorLabel={t('Actions.goToPage')}
          />
        </StyledUserList>
      )}
    </>
  );
};

export default memo(OrganisationForm);
