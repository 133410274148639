import useEndpoint from '../../hooks/useEndpoint';
import { UserDto } from '../../types/User.types';
import { ExpertProfileDto } from '../../types/ExpertProfile.types';
import { ExpertProfileApi } from '../../routes/ApiDefinitions';

const useExpertProfiles = (query?: {
  query?: string;
  researchPhases?: string[];
  therapyAreas?: string[];
  researchExpertises?: string[];
  roles?: string[];
  icd10?: string[];
}) => {
  const queryKey = query ? JSON.stringify(query) : '';
  const [{ data: profiles }] = useEndpoint<
    (UserDto & { expertProfile: ExpertProfileDto | null })[]
  >(
    {
      method: 'GET',
      options: { blockRenderingWhileLoading: true, callImmediately: true },
      url: ExpertProfileApi.path,
      queryParams: query ?? undefined,
    },
    [],
    queryKey
  );
  return profiles;
};

export default useExpertProfiles;
