import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@finbb/ui-components';
import { memo, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { StateContext } from '../../context/StateProvider/StateProvider';
import useEndpoint from '../../hooks/useEndpoint';
import usePermissionListCheck from '../../hooks/usePermissionListCheck';
import { ExpertProfileApi, UserApi } from '../../routes/ApiDefinitions';
import { OwnUser } from '../../routes/RouteDefinitions';
import { ExpertProfileDto } from '../../types/ExpertProfile.types';
import { UserDto } from '../../types/User.types';
import { XhrResponseType } from '../../types/XmlHttpRequest.types';
import ExpertProfileForm from '../ExpertProfileForm/ExpertProfileForm';
import UserApprovalForm from '../UserApprovalForm/UserApprovalForm';
import UserInfoForm from '../UserInfoForm/UserInfoForm';
import { StyledColumn, StyledRow, StyledRowColumn, StyledSubtleText } from './UserAccount.styles';
import { RouteParamsType } from './UserAccount.types';

const UserAccount = () => {
  const navigate = useNavigate();
  const { logout } = useAuth0();
  const { t } = useTranslation();
  const { state } = useContext(StateContext);

  const { id: idParam } = useParams<RouteParamsType>();

  const [isDeleteVisible, setDeleteVisibility] = useState(false);
  const [id, setId] = useState(idParam);
  const [ownPage, setOwnPage] = useState<boolean>(false);
  const [isOwnUser, setIsOwnUser] = useState<boolean>(false);
  const ownPagePath = OwnUser.path.split('/').pop();

  const [loadCacheKey, setLoadCacheKey] = useState<number>(1);
  const updateLoadCache = () => setLoadCacheKey((prev) => prev + 1);

  const [{ data: user, status: userStatus }, getUser] = useEndpoint<UserDto>(
    {
      method: 'GET',
      options: { blockRenderingWhileLoading: true },
      url: `${UserApi.path}/${id}`,
    },
    {} as UserDto,
    loadCacheKey
  );

  const [, deleteUser] = useEndpoint<XhrResponseType>(
    {
      method: 'DELETE',
      url: `${UserApi.path}/${id}`,
    },
    '200'
  );

  const [{ data: expertProfile, status }] = useEndpoint<ExpertProfileDto | undefined>(
    {
      method: 'GET',
      url: `${ExpertProfileApi.path}/${user?.expertProfileId}`,
      options: { blockRenderingWhileLoading: true },
      skip: !user?.expertProfileId,
    },
    undefined as ExpertProfileDto | undefined,
    loadCacheKey
  );

  const canUpsertExpertProfiles = usePermissionListCheck({
    permissions: [
      'create:all-expert-profiles',
      'create:same-organisation-expert-profiles',
      'update:all-expert-profiles',
      'update:same-organisation-expert-profiles',
      'create:own-expert-profile',
      'update:own-expert-profile',
    ],
    checkType: 'some',
  });

  const canShowButtons = usePermissionListCheck({
    permissions: ['delete:same-organisation-users', 'delete:all-users'],
    checkType: 'some',
  });

  const showExpertProfileForm =
    !user?.expertProfileId ||
    (user?.expertProfileId && status === 'SUCCESS' && userStatus === 'SUCCESS');

  const handleDeleteClick = async () => {
    // TODO: Change this to a custom modal component once we have one available in @finbb/ui-components
    // eslint-disable-next-line no-alert
    const isConfirmed = window.confirm(t('Messages.confirmOwnAccountDeletion'));

    if (isConfirmed) {
      await deleteUser();

      if (isOwnUser) {
        logout({ logoutParams: { returnTo: window.location.origin } });
      } else {
        navigate('/users');
      }
    }
  };

  useEffect(() => {
    if (state.user && [state.user.id, state.user.auth0Id, ownPagePath].includes(idParam)) {
      setId(state.user.id);
      setOwnPage(true);
      setIsOwnUser(id === state.user?.id);
    } else {
      setOwnPage(false);
      setId(idParam);
    }
  }, [idParam, ownPagePath, state.user]);

  useEffect(() => {
    if (id !== ownPagePath) {
      getUser();
    }
  }, [getUser, id, ownPagePath]);

  if (!user.id) {
    return <div>{t('Errors.userNotFound')}</div>;
  }

  if (user?.state === 'pending' && !ownPage) {
    return <UserApprovalForm user={user} />;
  }

  return (
    <StyledColumn>
      <StyledRow>
        <StyledRowColumn>
          <UserInfoForm user={user} isOwnAccount={isOwnUser} />
          {(id === state.user?.id || canShowButtons) && (
            <>
              <StyledSubtleText>
                <Button
                  onClick={() => setDeleteVisibility(!isDeleteVisible)}
                  text={t('Actions.showDeleteSomethingButton', {
                    something: isOwnUser ? 'Labels.ownAccount' : 'Labels.someoneElsesAccount',
                  })}
                  type="button"
                  variant="UNOBTRUSIVE"
                />
              </StyledSubtleText>

              {isDeleteVisible && (
                <Button
                  onClick={handleDeleteClick}
                  text={t('Actions.deleteAccount')}
                  type="button"
                  variant="UNOBTRUSIVE_CAUTION"
                />
              )}
            </>
          )}{' '}
        </StyledRowColumn>
        {canUpsertExpertProfiles && showExpertProfileForm && (
          <StyledRowColumn>
            <ExpertProfileForm
              user={user}
              expertProfile={expertProfile}
              reloadExpertProfile={updateLoadCache}
            />
          </StyledRowColumn>
        )}
      </StyledRow>
    </StyledColumn>
  );
};

export default memo(UserAccount);
