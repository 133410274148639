import { memo, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { StateContext } from '../../context/StateProvider/StateProvider';
import { Organisations, OwnOrganisation, OwnUser, Projects } from '../../routes/RouteDefinitions';

const DashboardPage = () => {
  const history = useNavigate();
  const { state } = useContext(StateContext);

  useEffect(() => {
    if (state.permissions && state.permissions.length > 0) {
      if (state.permissions?.includes('read:all-organisations')) {
        history(Organisations.path);
      } else if (state.permissions?.includes('read:all-projects')) {
        history(Projects.path);
      } else if (state.permissions?.includes('update:same-organisation')) {
        history(OwnOrganisation.path);
      } else {
        history(OwnUser.path);
      }
    }
  }, [history, state.permissions]);

  return <div />;
};

export default memo(DashboardPage);
