import { memo, useEffect } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import LandingPage from '../../pages/LandingPage/LandingPage';
import LoginPage from '../../pages/LoginPage/LoginPage';
import PrivacyPolicyPage from '../../pages/PrivacyPolicyPage/PrivacyPolicyPage';
import SignupPage from '../../pages/SignupPage/SignupPage';
import { Login, PrivacyPolicy, Root, Signup } from '../../routes/RouteDefinitions';
import { RouteActions } from '../../types/Route.types';
import { getRouteActionPath } from '../../utils/routes';

const UnauthenticatedSwitch = () => {
  const history = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    const unauthenticatedRoutes = [
      PrivacyPolicy.path,
      Root.path,
      Signup.path,
      Login.path,
      getRouteActionPath(Signup, RouteActions.SUCCESS),
    ];
    if (!unauthenticatedRoutes.includes(pathname)) {
      history(Root.path);
    }
  }, [history, pathname]);

  return (
    <Routes>
      <Route path={`${Signup.path}/*`} element={<SignupPage />} />
      <Route path={Login.path} element={<LoginPage />} />
      <Route path={PrivacyPolicy.path} element={<PrivacyPolicyPage />} />
      <Route path={Root.path} element={<LandingPage />} />
    </Routes>
  );
};

export default memo(UnauthenticatedSwitch);
