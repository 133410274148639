import { Button, ButtonGroup, ButtonType, Checkbox, Table } from '@finbb/ui-components';
import { memo, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Column } from 'react-table';

import useEndpoint from '../../hooks/useEndpoint';
import { ExpertProfileCategoriesApi } from '../../routes/ApiDefinitions';
import { ExpertProfiles, Users } from '../../routes/RouteDefinitions';
import { ExportProfileCategoryType } from '../../types/ExpertProfile.types';
import EmptyValue from '../EmptyValue/EmptyValue';
import SearchInput from '../SearchInput/SearchInput';
import { UserListDataType } from '../UserList/UserList.types';
import {
  HighlightContainer,
  StyledExpertProfileList,
  StyledPerfilterNotice,
  StyledPerfilterNoticeText,
} from './ExpertProfileList.styles';
import usePermissionListCheck from '../../hooks/usePermissionListCheck';
import { StateContext } from '../../context/StateProvider/StateProvider';
import useExpertProfiles from './useExpertProfiles';

type ExpertProfileListDataType = {
  keywords: string;
  therapyAreas: string;
  researchExpertises: string;
} & UserListDataType;

const Highlighter = ({ children, highlightText }: PropsWithChildren<{ highlightText: string }>) => {
  if (!children || highlightText === '') {
    return <HighlightContainer>{children}</HighlightContainer>;
  }

  const regex = new RegExp(`\\b([\\w\\s]*${highlightText}[\\w\\s]*)`, 'gi');

  const parts = typeof children === 'string' ? children.split(regex) : [];

  return (
    <HighlightContainer>
      {parts.map((part, index) =>
        regex.test(part) ? (
          // eslint-disable-next-line react/no-array-index-key
          <mark key={index}>{part}</mark>
        ) : (
          // eslint-disable-next-line react/no-array-index-key
          <span key={index}>{part}</span>
        )
      )}
    </HighlightContainer>
  );
};

const useTherapyAreas = () => {
  const [{ data }] = useEndpoint<ExportProfileCategoryType[]>(
    {
      method: 'GET',
      options: { callImmediately: true },
      url: `${ExpertProfileCategoriesApi.path}/therapy-area`,
    },
    []
  );

  return data;
};

const useResearchExpertises = () => {
  const [{ data }] = useEndpoint<ExportProfileCategoryType[]>(
    {
      method: 'GET',
      options: { callImmediately: true },
      url: `${ExpertProfileCategoriesApi.path}/research-expertise`,
    },
    []
  );

  return data;
};

const ExpertProfileList = () => {
  const { t } = useTranslation();
  const [listData, setListData] = useState([] as ExpertProfileListDataType[]);
  const [unfilteredData, setUnfilteredData] = useState([] as ExpertProfileListDataType[]);
  const [highlightString, setHighlightString] = useState('');
  const [displayKeywords, setDisplayKeywords] = useState(true);
  const { state } = useContext(StateContext);
  const [page, setPage] = useState<'enabled' | 'disabled'>('enabled');
  const { user } = state;

  const [searchParams, setSearchParams] = useSearchParams();
  const initialSearchQuery = searchParams.get('query') ?? undefined;
  const initialResearchPhasesQuery =
    searchParams.getAll('researchPhases[]').length > 0
      ? searchParams.getAll('researchPhases[]')
      : undefined;
  const initialTherapyAreaQuery =
    searchParams.getAll('therapyAreas[]').length > 0
      ? searchParams.getAll('therapyAreas[]')
      : undefined;
  const initialResearchExpertiseQuery =
    searchParams.getAll('researchExpertises[]').length > 0
      ? searchParams.getAll('researchExpertises[]')
      : undefined;
  const initialRoleQuery =
    searchParams.getAll('roles[]').length > 0 ? searchParams.getAll('roles[]') : undefined;
  const initialIcd10Query =
    searchParams.getAll('icd10Codes[]').length > 0
      ? searchParams.getAll('icd10Codes[]')
      : undefined;
  const initialRareNeurologicalDiseaseQuery =
    searchParams.getAll('rareNeurologicalDiseases[]').length > 0
      ? searchParams.getAll('rareNeurologicalDiseases[]')
      : undefined;

  const canFilterExpertProfileStatus =
    user?.roles?.includes('finbb-admin') || user?.roles?.includes('finbb-coordinator');

  const initialQuery = {
    roles: initialRoleQuery,
    researchPhases: initialResearchPhasesQuery,
    therapyAreas: initialTherapyAreaQuery,
    researchExpertises: initialResearchExpertiseQuery,
    icd10: initialIcd10Query,
    rareNeurologicalDiseases: initialRareNeurologicalDiseaseQuery,
    query: initialSearchQuery,
  };

  const query = {
    ...initialQuery,
    enabled: canFilterExpertProfileStatus ? page === 'enabled' : undefined,
  };

  const profiles = useExpertProfiles(query);
  const therapyAreas = useTherapyAreas();
  const researchExpertises = useResearchExpertises();
  const navigate = useNavigate();
  const canEditAllUsers = usePermissionListCheck({
    permissions: ['update:all-users'],
  });

  const reloadPageWithoutQueryParams = () => {
    setSearchParams(undefined);
    navigate(0);
  };

  useEffect(() => {
    const therapyAreasMappedByTherapyAreaId = therapyAreas.reduce<{
      [key: string]: string;
    }>((acc, therapyArea) => {
      acc[therapyArea.id] = therapyArea.name;
      return acc;
    }, {});

    const researchExpertisesMappedByResearchExpertiseId = researchExpertises.reduce<{
      [key: string]: string;
    }>((acc, researchExpertise) => {
      acc[researchExpertise.id] = researchExpertise.name;
      return acc;
    }, {});

    const mappedUsers: ExpertProfileListDataType[] = profiles.map((profile) => {
      const { expertProfile } = profile;
      return {
        id: profile.id,
        name: `${profile.firstName} ${profile.lastName}`,
        email: profile.email,
        state: profile.state,
        phone: profile.phone,
        expertProfileId: expertProfile?.id,
        organisationId: profile.organisationId,
        therapyAreas:
          expertProfile?.therapyAreas
            .map((c) => therapyAreasMappedByTherapyAreaId[c.id])
            .join(', ') ?? '',
        researchExpertises:
          expertProfile?.researchExpertises
            .map((c) => researchExpertisesMappedByResearchExpertiseId[c.id])
            .join(', ') ?? '',
        keywords: expertProfile?.keywords.map((k) => k.value).join(', ') ?? '',
      };
    });

    setUnfilteredData([...mappedUsers] as ExpertProfileListDataType[]);
    setListData([...mappedUsers]);
  }, [profiles, researchExpertises, therapyAreas]);

  const keywordColumn: Column<ExpertProfileListDataType>[] = displayKeywords
    ? [
        {
          Header: t('Labels.keywords'),
          Cell: ({ value }) => <Highlighter highlightText={highlightString}>{value}</Highlighter>,
          accessor: 'keywords',
        },
      ]
    : [];

  const columns: Column<ExpertProfileListDataType>[] = [
    {
      Header: t('Labels.name'),
      Cell: ({ row, value }) => {
        if (canEditAllUsers || row.original.organisationId === user?.organisationId) {
          return (
            <Link to={`${Users.path}/${row.original.id}`}>
              <Highlighter highlightText={highlightString}>{value}</Highlighter>
            </Link>
          );
        }
        return (
          <Link to={`${ExpertProfiles.path}/${row.original.expertProfileId}`}>
            <Highlighter highlightText={highlightString}>{value}</Highlighter>
          </Link>
        );
      },
      accessor: 'name',
    },
    {
      Header: t('Labels.email'),
      accessor: 'email',
    },
    {
      Header: t('Labels.phone'),
      accessor: 'phone',
    },
    {
      Header: t('Labels.therapyAreas'),
      Cell: ({ value }) => <Highlighter highlightText={highlightString}>{value}</Highlighter>,
      accessor: 'therapyAreas',
    },
    {
      Header: t('Labels.researchExpertises'),
      Cell: ({ value }) => <Highlighter highlightText={highlightString}>{value}</Highlighter>,
      accessor: 'researchExpertises',
    },
    ...keywordColumn,
  ];

  const pageButtons: ButtonType[] = [
    {
      onClick: () => setPage('enabled'),
      text: t('Labels.enabled'),
      variant: page === 'enabled' ? 'CALL_TO_ACTION' : undefined,
    },
    {
      onClick: () => setPage('disabled'),
      text: t('Labels.disabled'),
      variant: page === 'disabled' ? 'CALL_TO_ACTION' : undefined,
    },
  ];

  const initialFilters =
    initialIcd10Query !== undefined ||
    initialResearchPhasesQuery !== undefined ||
    initialTherapyAreaQuery !== undefined ||
    initialRoleQuery !== undefined ||
    initialResearchExpertiseQuery !== undefined ||
    initialRareNeurologicalDiseaseQuery !== undefined ||
    initialSearchQuery !== undefined;

  return (
    <StyledExpertProfileList>
      {initialFilters && (
        <StyledPerfilterNotice>
          <StyledPerfilterNoticeText>{t('Labels.prefilteredResults')}</StyledPerfilterNoticeText>
          <Button
            type="button"
            onClick={reloadPageWithoutQueryParams}
            variant="UNOBTRUSIVE"
            text={t('Actions.clear')}
          />
        </StyledPerfilterNotice>
      )}
      <Checkbox
        label={t('Labels.showKeywords')}
        isChecked={displayKeywords}
        onChange={() => setDisplayKeywords(!displayKeywords)}
      />
      {canFilterExpertProfileStatus && <ButtonGroup buttons={pageButtons} />}
      <SearchInput
        data={unfilteredData}
        callback={(filteredData, searchQuery) => {
          setListData(filteredData);
          setHighlightString(searchQuery);
        }}
        filterableFields={['keywords', 'name', 'therapyAreas', 'researchExpertises']}
      />
      <Table
        allowSorting
        columns={columns}
        data={listData}
        emptyValueIndicator={<EmptyValue />}
        noDataMessage={t('Labels.noData')}
        pageSelectorLabel={t('Actions.goToPage')}
      />
    </StyledExpertProfileList>
  );
};

export default memo(ExpertProfileList);
