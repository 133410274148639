import { ButtonGroup, ButtonType, Table } from '@finbb/ui-components';
import { memo, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Column } from 'react-table';
import { StateContext } from '../../context/StateProvider/StateProvider';
import useEndpoint from '../../hooks/useEndpoint';
import { OrganisationApi } from '../../routes/ApiDefinitions';
import { Organisations, Users } from '../../routes/RouteDefinitions';
import { OrganisationDto } from '../../types/Organisation.types';
import { userState } from '../../types/User.types';
import { formatUserName } from '../../utils/data';
import EmptyValue from '../EmptyValue/EmptyValue';
import { StyledUserList } from './UserList.styles';
import { UserListDataType, UserListPageType } from './UserList.types';
import SearchInput from '../SearchInput/SearchInput';
import useUsers from './useUsers';

const filterPageData = (pageData: UserListDataType[], page: UserListPageType) =>
  pageData.filter((datum) => datum.state === page);

const UserList = () => {
  const { t } = useTranslation();
  const { state } = useContext(StateContext);
  const [canSwitchPage, setCanSwitchPage] = useState(false);
  const [selectedPage, setPage] = useState<UserListPageType>(userState.accepted);
  const [acceptedUsers, setAcceptedUsers] = useState([] as Array<UserListDataType>);
  const [pendingUsers, setPendingUsers] = useState([] as Array<UserListDataType>);
  const [listData, setListData] = useState([] as Array<UserListDataType>);

  const getPageData = useCallback(
    () => (selectedPage === userState.pending ? pendingUsers : acceptedUsers),
    [selectedPage, pendingUsers, acceptedUsers]
  );

  const [{ data: organisations }, getOrganisations] = useEndpoint<OrganisationDto[]>(
    {
      method: 'GET',
      options: { blockRenderingWhileLoading: true },
      url: OrganisationApi.path,
    },
    []
  );
  const users = useUsers(state);

  useEffect(() => {
    const data: UserListDataType[] = users.map((user) => ({
      email: user.email,
      id: user.id,
      name: formatUserName(user),
      organisation:
        user.state === userState.pending
          ? user.suggestedOrganisation?.name
          : organisations?.find((organisation) => organisation.id === user.organisationId)?.name,
      organisationId: user.organisationId,
      phone: user.phone,
      state: user.state,
    }));

    setAcceptedUsers(filterPageData(data, userState.accepted));
    setPendingUsers(filterPageData(data, userState.pending));
    setListData(filterPageData(data, selectedPage));
  }, [users, organisations, selectedPage]);

  useEffect(() => {
    setListData(getPageData());
  }, [selectedPage, getPageData]);

  const canUpdateOtherUsers = state.permissions?.some((p) =>
    ['update:all-users', 'update:same-organisation-users'].includes(p)
  );
  const canUpdateAnyOrganisation = state.permissions?.includes('update:all-organisations');

  const hasPendingUsers = !!users.find((user) => user.state === 'pending');

  const columns: Column<UserListDataType>[] = [
    {
      Header: t('Labels.name'),
      Cell: ({ row, value }) =>
        canUpdateOtherUsers ? <Link to={`${Users.path}/${row.original.id}`}>{value}</Link> : value,
      accessor: 'name',
    },
    {
      Header: t('Labels.email'),
      accessor: 'email',
    },
    {
      Header: t('Labels.phone'),
      accessor: 'phone',
    },
    {
      Header: t('Labels.organisation'),
      Cell: ({ row, value }) =>
        row.original.state === 'accepted' && canUpdateAnyOrganisation ? (
          <Link to={`${Organisations.path}/${row.original.organisationId}`}>{value}</Link>
        ) : (
          <>{value}</>
        ),
      accessor: 'organisation',
    },
  ];

  const hiddenColumns: string[] = [
    ...(!state.permissions?.includes('read:all-organisations') ? ['organisation'] : []),
  ];

  const pageButtons: ButtonType[] = [
    {
      onClick: () => setPage('pending'),
      text: t('AccountStates.pending'),
      variant: selectedPage === 'pending' ? 'CALL_TO_ACTION' : undefined,
    },
    {
      onClick: () => setPage('accepted'),
      text: t('AccountStates.accepted'),
      variant: selectedPage === 'accepted' ? 'CALL_TO_ACTION' : undefined,
    },
  ];

  useEffect(() => {
    if (state.permissions?.includes('read:all-organisations')) {
      getOrganisations();
    }
  }, [state.permissions, getOrganisations]);

  useEffect(() => {
    const canApproveUsers = Boolean(state.permissions?.includes('update:all-organisations'));
    setCanSwitchPage(canApproveUsers);

    if (canApproveUsers && hasPendingUsers) {
      setPage('pending');
    }
  }, [hasPendingUsers, state.permissions]);

  const filterableFields: (keyof UserListDataType)[] = ['organisation', 'name', 'email'];

  return (
    <StyledUserList>
      {canSwitchPage && hasPendingUsers && <ButtonGroup buttons={pageButtons} />}

      <SearchInput
        data={getPageData()}
        callback={(filteredData) => setListData(filteredData)}
        filterableFields={filterableFields}
      />

      <Table
        allowSorting
        columns={columns}
        data={listData}
        emptyValueIndicator={<EmptyValue />}
        hiddenColumns={hiddenColumns}
        noDataMessage={t('Labels.noData')}
        pageSelectorLabel={t('Actions.goToPage')}
      />
    </StyledUserList>
  );
};

export default memo(UserList);
