import { initialState } from './StateProvider';
import { ActionType, StateType } from './StateProvider.types';

const StateReducer = (state: StateType, action: ActionType): StateType => {
  switch (action.type) {
    case 'SET_TABBING_AROUND': {
      return {
        ...state,
        isUserTabbingAround: action.payload,
      };
    }
    case 'SET_PERMISSIONS': {
      return {
        ...state,
        permissions: action.payload,
      };
    }
    case 'SET_USER': {
      return {
        ...state,
        user: action.payload,
      };
    }
    case 'ADD_PENDING_FETCH': {
      return {
        ...state,
        pendingCalls: state.pendingCalls + 1,
      };
    }
    case 'REMOVE_PENDING_FETCH': {
      return {
        ...state,
        pendingCalls: Math.max(state.pendingCalls - 1, 0),
      };
    }
    case 'SET_ERROR_MESSAGE': {
      return {
        ...state,
        errorMessage: action.payload,
      };
    }
    case 'PURGE_STATE':
      return initialState;
    default:
      return state;
  }
};

export default StateReducer;
