interface ConfigType {
  auth0Audience?: string;
  auth0ClientId?: string;
  auth0Domain?: string;
}

const useConfig = (): ConfigType => ({
  auth0Audience: process.env.REACT_APP_AUTH0_PLATFORM_API_AUDIENCE,
  auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN,
});

export default useConfig;
