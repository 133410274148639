import { useContext } from 'react';

import { StateContext } from '../context/StateProvider/StateProvider';
import { PermissionType } from '../types/Permission.types';

type CheckType = 'every' | 'some';

export const usePermissionListCheck = ({
  permissions,
  checkType = 'some',
}: {
  permissions: PermissionType[];
  checkType?: CheckType;
}) => {
  const { state } = useContext(StateContext);

  const userPermissions = state.permissions || [];

  const check = (permission: PermissionType) => {
    if (!userPermissions.length) {
      return false;
    }

    return userPermissions.includes(permission);
  };

  return checkType === 'some' ? permissions.some(check) : permissions.every(check);
};

export default usePermissionListCheck;
