import { MultiSelect, SelectOptionType } from '@finbb/ui-components';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { RoleType } from '../../types/Role.types';

interface RoleMultiSelectProps {
  value: RoleType[];
  fieldName: string;
  roles: RoleType[];
}

export default function RoleMultiSelect({ roles, value, fieldName }: RoleMultiSelectProps) {
  const { t } = useTranslation();
  const [filterString, setFilterString] = useState('');
  const { setFieldValue, errors } = useFormikContext<{
    [key: string]: RoleType[];
  }>();

  const options: SelectOptionType<RoleType>[] = roles.map((role) => ({
    name: t(`Roles.${role}`),
    value: role,
  }));

  const filteredOptions =
    filterString === ''
      ? options
      : options.filter((option) => option.name.toLowerCase().includes(filterString.toLowerCase()));

  return (
    <MultiSelect
      label={t('Labels.userRole')}
      name="roles"
      onChanged={(newValue) => setFieldValue(fieldName, newValue)}
      onInputChanged={(inputValue) =>
        inputValue ? setFilterString(inputValue) : setFilterString('')
      }
      options={filteredOptions}
      placeholder={t('Labels.select')}
      required
      allowNewValues={false}
      value={value}
      variant={errors[fieldName] ? 'CAUTION' : 'NORMAL'}
    />
  );
}
