import { SchemaOf, array, boolean, object } from 'yup';

import { ExpertProfileContentType } from './ExpertProfileForm.types';

export const ExpertProfileFormSchema: SchemaOf<ExpertProfileContentType> = object({
  enabled: boolean().optional(),
  keywords: array().optional(),
  researchPhases: array().optional(),
  researchExpertises: array().optional(),
  roles: array().optional(),
  therapyAreas: array().optional(),
  icd10Codes: array().optional(),
  rareNeurologicalDiseases: array().optional(),
});
