import styled, { css } from 'styled-components';

export const StyledSubtleText = styled.p`
  ${({ theme }) => theme.objects.Copy.Subtle}
  font-size: ${({ theme }) => theme.values.textSizes.XSMALL};
`;

export const StyledHeading = styled.p`
  ${({ theme }) => theme.objects.Heading.Scale.MEDIUM}
  ${({ theme }) => theme.objects.Heading.Style.Primary}
`;

export const StyledColumn = styled.div`
  ${({ theme }) => theme.mixins.Container('COLUMN')}
`;

export const StyledRow = styled.div`
  ${({ theme }) => theme.mixins.Container('COLUMN')}

  ${({ theme }) =>
    theme.mixins.RespondTo(
      'LAPTOP',
      css`
        ${theme.mixins.Container('ROW', {
          wrap: 'wrap',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        })}
      `
    )}

  margin-right: unset;
  flex-wrap: wrap;
`;

export const StyledRowColumn = styled.div`
  ${({ theme }) => theme.mixins.Container('COLUMN', { wrap: 'wrap' })}
  flex: 25%;
  flex-wrap: wrap;
`;
