import { genericRoles, RoleType } from '../types/Role.types';

interface ApplicationLinkType {
  text: string;
  href: string;
}

export const getAppLinksForRole = (
  userRoles: RoleType[],
  t: (arg0: string) => string
): ApplicationLinkType[] => {
  const applicationLinks: ApplicationLinkType[] = [];

  // if is researcher role
  if (userRoles[0] === genericRoles[5]) {
    (
      JSON.parse(
        process.env.REACT_APP_RESEARCHER_APPLICATION_LINKS || '[]'
      ) as ApplicationLinkType[]
    )
      .map((appLink) => ({ text: t(`ApplicationNames.${appLink.text}`), href: appLink.href }))
      .forEach((i) => {
        applicationLinks.push(i);
      });
  } else if (userRoles === undefined || userRoles.length === 0) {
    // No role, no links yet. Either user is not logged in, or not
    // approved. Only show the "Platform" link so the user knows where
    // they are.
    applicationLinks.push({
      text: t('ApplicationNames.platform') as string,
      href: process.env.REACT_APP_URL_PLATFORM as string,
    });
  } else {
    // "admin" level links
    (JSON.parse(process.env.REACT_APP_APPLICATION_LINKS || '[]') as ApplicationLinkType[])
      .map((appLink) => ({ text: t(`ApplicationNames.${appLink.text}`), href: appLink.href }))
      .forEach((i) => {
        applicationLinks.push(i);
      });
  }

  return applicationLinks;
};
