import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { Reducer, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';

import { StateContext } from '../../context/StateProvider/StateProvider';
import reducer from './reducer';
import {
  ActionType,
  OptionsType,
  ParamsType,
  PromiseFunctionType,
  RejectType,
  RequestType,
  ResolveType,
  StateType,
} from './types';

const useEndpoint = <ResponseDataType>(
  { method = 'GET', options, queryParams, url, skip }: ParamsType,
  initialData: ResponseDataType,
  // eslint-disable-next-line
  listenToThisVariable?: any
): [StateType<ResponseDataType>, PromiseFunctionType<ResponseDataType>] => {
  const { getAccessTokenSilently } = useAuth0();

  const initialState: StateType<ResponseDataType> = {
    data: initialData,
    error: null,
    status: 'IDLE',
  };

  const defaultOptions: OptionsType = {
    blockRenderingWhileLoading: false,
    callImmediately: false,
    noAuth: false,
  };

  const config: OptionsType = {
    ...defaultOptions,
    ...options,
  };

  const { dispatch: dispatchGlobal } = useContext(StateContext);

  const [shouldCallApi, setShouldCallApi] = useState(config.callImmediately);
  const [request, setRequest] = useState<RequestType>();
  const [resolve, setResolve] = useState<ResolveType<ResponseDataType>>();
  const [reject, setReject] = useState<RejectType>();

  const [response, dispatch] = useReducer<
    Reducer<StateType<ResponseDataType>, ActionType<ResponseDataType>>
  >(reducer, initialState);

  const urlSearchParams = queryParams
    ? new URLSearchParams(queryParams as Record<string, string>)
    : '';

  const urlWithQueryParams = useMemo(
    () => (urlSearchParams ? `${url}?${urlSearchParams}` : url),
    [url, urlSearchParams]
  );

  const requester: PromiseFunctionType<ResponseDataType> = useCallback(
    (data) =>
      new Promise((promiseResolve, promiseReject) => {
        setReject(() => promiseReject);
        setResolve(() => promiseResolve);
        setRequest(() => ({
          data,
          method,
          url,
          queryParams,
        }));
      }),
    [method, urlWithQueryParams]
  );

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    const callEndpoint = async () => {
      let headers = {};

      if (!config.noAuth) {
        try {
          const accessToken = await getAccessTokenSilently();

          headers = {
            ...headers,
            Authorization: `Bearer ${accessToken}`,
          };
        } catch (err) {
          dispatch({ type: 'FETCH_ERROR', payload: (err as Error).message });
        }
      }

      const axiosRequest = {
        ...request,
        cancelToken: cancelToken.token,
        headers,
        params: queryParams,
      };

      try {
        axios.defaults.baseURL = process.env.REACT_APP_API_DOMAIN;
        if (options?.blockRenderingWhileLoading) {
          dispatchGlobal({ type: 'ADD_PENDING_FETCH' });
        }

        dispatch({ type: 'FETCHING_DATA' });
        const { data } = await axios(axiosRequest);
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
        setShouldCallApi(false);
      } catch (err) {
        dispatch({ type: 'FETCH_ERROR', payload: (err as Error).message });
      } finally {
        setShouldCallApi(false);
        if (options?.blockRenderingWhileLoading) {
          dispatchGlobal({ type: 'REMOVE_PENDING_FETCH' });
        }
      }
    };

    if (request && !skip) {
      callEndpoint();
    }

    return () => cancelToken.cancel();
  }, [
    skip,
    config.noAuth,
    dispatchGlobal,
    getAccessTokenSilently,
    options?.blockRenderingWhileLoading,
    request,
    listenToThisVariable,
  ]);

  useEffect(() => {
    if (shouldCallApi && !skip) {
      requester();
    }
  }, [shouldCallApi, requester]);

  useEffect(() => {
    if (typeof listenToThisVariable !== 'undefined') {
      setShouldCallApi(true);
    }
  }, [listenToThisVariable]);

  useEffect(() => {
    if (response.status === 'ERROR' && reject) {
      reject(response.error);
    }

    if (response.status === 'SUCCESS' && resolve) {
      resolve(response.data);
    }
  }, [reject, resolve, response]);

  return [response, requester];
};

export default useEndpoint;
