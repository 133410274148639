import { Normalize } from 'react-i18next';
import { TranslationType } from '../i18n/i18n.types';
import { PermissionType } from './Permission.types';

export const RouteActions = {
  CREATE: 'create',
  PENDING: 'pending',
  SUCCESS: 'success',
} as const;

export type RouteActionType = (typeof RouteActions)[keyof typeof RouteActions];

export interface RouteType {
  actions?: RouteActionType[];
  path: string;
}

interface RoutePermissionsType {
  all?: PermissionType[];
  atLeastOne?: PermissionType[];
}

export interface NavigationRouteType extends RouteType {
  titleKey: Normalize<TranslationType>;
  requiredPermissions?: RoutePermissionsType;
}
