import { Table } from '@finbb/ui-components';
import { memo, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Column } from 'react-table';
import { StateContext } from '../../context/StateProvider/StateProvider';
import useEndpoint from '../../hooks/useEndpoint';
import { ProjectApi } from '../../routes/ApiDefinitions';
import { Projects } from '../../routes/RouteDefinitions';
import { ProjectDto, ProjectStateType } from '../../types/Project.types';
import { formatUserName } from '../../utils/data';
import EmptyValue from '../EmptyValue/EmptyValue';
import { StyledBadge } from './ProjectList.styles';
import { ProjectListDataType } from './ProjectList.types';
import SearchInput from '../SearchInput/SearchInput';

const ProjectList = () => {
  const { t } = useTranslation();
  const { state } = useContext(StateContext);
  const [originalData, setOriginalData] = useState([] as ProjectListDataType[]);
  const [listData, setListData] = useState([] as ProjectListDataType[]);

  const [{ data: projects }] = useEndpoint<ProjectDto[]>(
    {
      method: 'GET',
      options: { blockRenderingWhileLoading: true, callImmediately: true },
      queryParams: {
        organisationId: !state.permissions?.includes('read:all-projects')
          ? state.user?.organisationId
          : undefined,
      },
      url: ProjectApi.path,
    },
    []
  );

  const canUpdateProjects =
    state.permissions?.includes('update:all-projects') ||
    state.permissions?.includes('update:same-organisation-projects');

  useEffect(() => {
    const data: ProjectListDataType[] = projects
      .map((project) => ({
        description: project.description,
        id: project.id,
        projectCoordinatorName: formatUserName(project.projectCoordinator),
        projectResearcherName: formatUserName(project.projectResearcher),
        projectId: project.projectId,
        state: project.state,
        organisation: project.organisation?.name ?? '',
      }))
      .sort((a) => {
        if (a.state === 'active') {
          return -1;
        }

        return 0;
      });

    setListData(data);
    setOriginalData(data);
  }, [projects]);

  const getTranslationByStateType = (projectState: ProjectStateType) =>
    t(projectState === 'archived' ? 'ProjectStates.archived' : 'ProjectStates.active');

  const columns: Column<ProjectListDataType>[] = [
    {
      Header: t('Labels.organisation'),
      accessor: 'organisation',
    },
    {
      Header: t('Labels.id'),
      Cell: ({ row, value }) =>
        canUpdateProjects ? <Link to={`${Projects.path}/${row.original.id}`}>{value}</Link> : value,
      accessor: 'projectId',
    },
    {
      Header: t('Labels.title'),
      Cell: ({ row, value }) =>
        canUpdateProjects ? <Link to={`${Projects.path}/${row.original.id}`}>{value}</Link> : value,
      accessor: 'description',
    },
    {
      Header: t('Labels.projectCoordinator'),
      accessor: 'projectCoordinatorName',
    },
    {
      Header: t('Labels.projectResearcher'),
      accessor: 'projectResearcherName',
    },
    {
      Header: t('Labels.state'),
      Cell: ({ value }) => <StyledBadge>{getTranslationByStateType(value)}</StyledBadge>,
      accessor: 'state',
    },
  ];

  const hiddenColumns: string[] = [
    ...(!state.permissions?.includes('read:all-organisations') ? ['organisation'] : []),
  ];

  return (
    <>
      <SearchInput
        data={originalData}
        callback={(filteredData) => setListData(filteredData as ProjectListDataType[])}
      />
      <Table
        allowSorting
        columns={columns}
        data={listData}
        emptyValueIndicator={<EmptyValue />}
        hiddenColumns={hiddenColumns}
        noDataMessage={t('Labels.noData')}
        pageSelectorLabel={t('Actions.goToPage')}
      />
    </>
  );
};

export default memo(ProjectList);
