import { useParams } from 'react-router-dom';
import React from 'react';
import { Input, LoadingIndicator } from '@finbb/ui-components';
import { useTranslation } from 'react-i18next';
import useExpertProfiles from '../ExpertProfileList/useExpertProfiles';
import { StyledColumn, StyledRow, StyledRowColumn } from '../UserAccount/UserAccount.styles';
import { StyledFieldSet } from '../UserInfoForm/UserInfoForm.styles';
import useEndpoint from '../../hooks/useEndpoint';
import { ExportProfileCategoryType } from '../../types/ExpertProfile.types';
import { ExpertProfileCategoriesApi } from '../../routes/ApiDefinitions';
import ExpertProfileForm from '../ExpertProfileForm/ExpertProfileForm';

interface ExpertProfileCategories {
  therapyAreas: ExportProfileCategoryType[];
  researchExpertises: ExportProfileCategoryType[];
  researchPhases: ExportProfileCategoryType[];
  icd10Codes: ExportProfileCategoryType[];
  roles: ExportProfileCategoryType[];
}

const useExpertProfileCategories = () => {
  const [{ data }] = useEndpoint<ExpertProfileCategories | null>(
    {
      method: 'GET',
      options: { callImmediately: true },
      url: `${ExpertProfileCategoriesApi.path}`,
    },
    null
  );

  return data;
};

const mapCategoriesById = (categories: ExportProfileCategoryType[]) =>
  categories.reduce((acc, category) => {
    acc[category.id] = category;
    return acc;
  }, {} as { [key: string]: ExportProfileCategoryType });

const ExpertProfilePage = () => {
  const { id: expertProfileId } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const profiles = useExpertProfiles();
  const categories = useExpertProfileCategories();
  const profile = profiles.find((p) => p.expertProfileId === expertProfileId);

  if (!profile || !categories) {
    return <LoadingIndicator />;
  }

  const therapyAreasById = mapCategoriesById(categories.therapyAreas);
  const researchExpertisesById = mapCategoriesById(categories.researchExpertises);
  const researchPhasesById = mapCategoriesById(categories.researchPhases);
  const icd10CodesById = mapCategoriesById(categories.icd10Codes);

  const mappedProfile = {
    ...profile,
    roles: profile.roles,
    therapyAreas: profile.expertProfile?.therapyAreas.map((c) => therapyAreasById[c.id].name),
    researchExpertises: profile.expertProfile?.researchExpertises.map(
      (c) => researchExpertisesById[c.id].name
    ),
    researchPhases: profile.expertProfile?.researchPhases.map((c) => researchPhasesById[c.id].name),
    icd10Codes: profile.expertProfile?.icd10Codes.map((c) => icd10CodesById[c.id].name),
    keywords: profile.expertProfile?.keywords.map((c) => c.value),
  };

  return (
    <StyledColumn>
      <StyledRow>
        <StyledRowColumn>
          <StyledFieldSet>
            <Input
              label={t('Labels.firstName')}
              value={mappedProfile.firstName}
              readOnly
              name="firstName"
              message={undefined}
              variant={undefined}
              onChange={() => null}
            />
            <Input
              label={t('Labels.lastName')}
              value={mappedProfile.lastName}
              readOnly
              name="lastName"
              message={undefined}
              variant={undefined}
              onChange={() => null}
            />
          </StyledFieldSet>
          <StyledFieldSet>
            <Input
              label={t('Labels.email')}
              value={mappedProfile.email}
              readOnly
              name="email"
              message={undefined}
              variant={undefined}
              onChange={() => null}
            />
            <Input
              label={t('Labels.phoneIntlFormat')}
              value={mappedProfile.phone}
              readOnly
              name="phone"
              message={undefined}
              variant={undefined}
              onChange={() => null}
            />
          </StyledFieldSet>
          <StyledFieldSet>
            <Input
              label={t('Labels.userRole')}
              message={undefined}
              onChange={() => null}
              readOnly
              value={mappedProfile?.roles?.join(', ') ?? ''}
              variant={undefined}
              name="roles"
            />
          </StyledFieldSet>
        </StyledRowColumn>
        <StyledRowColumn>
          <ExpertProfileForm
            user={mappedProfile}
            expertProfile={profile.expertProfile ?? undefined}
          />
        </StyledRowColumn>
      </StyledRow>
    </StyledColumn>
  );
};

export default ExpertProfilePage;
