import { memo, useContext, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import UserAccount from '../../components/UserAccount/UserAccount';
import UserList from '../../components/UserList/UserList';
import { StateContext } from '../../context/StateProvider/StateProvider';
import { OwnUser } from '../../routes/RouteDefinitions';

const UserManagementPage = () => {
  const history = useNavigate();
  const { state } = useContext(StateContext);

  useEffect(() => {
    if (state.permissions && state.permissions.length > 0) {
      if (
        !(
          state.permissions?.includes('read:all-users') ||
          state.permissions?.includes('read:same-organisation-users')
        )
      ) {
        history(OwnUser.path);
      }
    }
  }, [history, state.permissions]);

  return (
    <Routes>
      <Route path=":id" element={<UserAccount />} />
      <Route path="" element={<UserList />} />
    </Routes>
  );
};

export default memo(UserManagementPage);
