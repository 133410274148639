import { NavigationRouteType, RouteActions, RouteType } from '../types/Route.types';

export const Organisations: NavigationRouteType = {
  actions: [RouteActions.CREATE],
  path: '/organisations',
  requiredPermissions: {
    atLeastOne: [
      'create:all-organisations',
      'delete:all-organisations',
      'read:all-organisations',
      'update:all-organisations',
    ],
  },
  titleKey: 'Routes.organisations',
};

export const OwnOrganisation: NavigationRouteType = {
  path: '/organisation',
  requiredPermissions: {
    all: ['update:same-organisation'],
  },
  titleKey: 'Routes.ownOrganisation',
};

export const OwnUser: RouteType = {
  path: '/users/me',
};

export const Logout: RouteType = {
  path: '/logout',
};

export const Login: RouteType = {
  path: '/login',
};

export const Projects: NavigationRouteType = {
  actions: [RouteActions.CREATE],
  path: '/projects',
  requiredPermissions: {
    atLeastOne: ['read:same-organisation-projects', 'read:all-projects'],
  },
  titleKey: 'Routes.projects',
};

export const Root: NavigationRouteType = {
  path: '/',
  titleKey: 'Routes.root',
};

export const Signup: NavigationRouteType = {
  actions: [RouteActions.PENDING, RouteActions.SUCCESS],
  path: '/signup',
  titleKey: 'Routes.signup',
};

export const Users: NavigationRouteType = {
  path: '/users',
  requiredPermissions: {
    atLeastOne: ['read:all-users', 'read:same-organisation-users'],
  },
  titleKey: 'Routes.users',
};

export const ExpertProfiles: NavigationRouteType = {
  path: '/expert-profiles',
  requiredPermissions: {
    atLeastOne: ['read:all-expert-profiles', 'read:same-organisation-expert-profiles'],
  },
  titleKey: 'Routes.expertProfiles',
};

export const PrivacyPolicy: RouteType = {
  path: '/privacy-policy',
};
