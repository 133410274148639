import { memo } from 'react';
import { Route, Routes } from 'react-router-dom';
import SignupForm from '../../components/SignupForm/SignupForm';
import SignupPending from '../../components/SignupPending/SignupPending';
import SignupSuccess from '../../components/SignupSuccess/SignupSuccess';
import { RouteActions } from '../../types/Route.types';

const SignupPage = () => (
  <Routes>
    <Route path={RouteActions.PENDING} element={<SignupPending />} />
    <Route path={RouteActions.SUCCESS} element={<SignupSuccess />} />
    <Route path="" element={<SignupForm />} />
  </Routes>
);

export default memo(SignupPage);
