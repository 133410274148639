import { Input, Select, SelectOptionType, SeparatorLine } from '@finbb/ui-components';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { languages, LanguageType } from '../../../i18n/i18n.types';
import { StyledFieldSet, StyledSpan } from '../SignupForm.styles';
import { PartialFormType } from '../SignupForm.types';

const UserInfo = ({ errors, onBlur, onChange, touched, values }: PartialFormType) => {
  const { t } = useTranslation();

  const languageOptions: SelectOptionType<LanguageType>[] = languages.map((language) => ({
    name: t(`Languages.${language}`),
    value: language,
  }));

  return (
    <>
      <StyledFieldSet>
        <Input
          label={t('Labels.email')}
          message={errors.email && touched.email ? errors.email : ''}
          name="email"
          onBlur={onBlur}
          onChange={onChange}
          required
          type="email"
          value={values.email}
          variant={errors.email && touched.email ? 'CAUTION' : undefined}
        />

        <StyledSpan>{t('Labels.emailCaution')}</StyledSpan>

        <Input
          label={t('Labels.password')}
          message={errors.password && touched.password ? errors.password : ''}
          name="password"
          onBlur={onBlur}
          onChange={onChange}
          required
          type="password"
          value={values.password}
          variant={errors.password && touched.password ? 'CAUTION' : undefined}
        />
        <Input
          label={t('Labels.confirmPassword')}
          message={errors.passwordConfirm && touched.passwordConfirm ? errors.passwordConfirm : ''}
          name="passwordConfirm"
          onBlur={onBlur}
          onChange={onChange}
          required
          type="password"
          value={values.passwordConfirm}
          variant={errors.passwordConfirm && touched.passwordConfirm ? 'CAUTION' : undefined}
        />
        <Select
          label={t('Labels.preferredLanguage')}
          name="lang"
          onBlur={onBlur}
          onChange={onChange}
          options={languageOptions}
          placeholder={t('Labels.select')}
          required
          value={values.lang}
        />
      </StyledFieldSet>

      <SeparatorLine />

      <StyledFieldSet>
        <Input
          label={t('Labels.firstName')}
          message={errors.firstName && touched.firstName ? errors.firstName : ''}
          name="firstName"
          onBlur={onBlur}
          onChange={onChange}
          required
          value={values.firstName}
          variant={errors.firstName && touched.firstName ? 'CAUTION' : undefined}
        />
        <Input
          label={t('Labels.lastName')}
          message={errors.lastName && touched.lastName ? errors.lastName : ''}
          name="lastName"
          onBlur={onBlur}
          onChange={onChange}
          required
          value={values.lastName}
          variant={errors.lastName && touched.lastName ? 'CAUTION' : undefined}
        />
        <Input
          label={t('Labels.phoneIntlFormat')}
          message={errors.phone && touched.phone ? errors.phone : ''}
          name="phone"
          onBlur={onBlur}
          onChange={onChange}
          required
          value={values.phone}
          variant={errors.phone && touched.phone ? 'CAUTION' : undefined}
        />
      </StyledFieldSet>
    </>
  );
};

export default memo(UserInfo);
