import { ActionType, StateType } from './types';

const reducer = <T>(state: StateType<T>, action: ActionType<T>): StateType<T> => {
  switch (action.type) {
    case 'FETCHING_DATA': {
      return {
        ...state,
        data: state.data,
        status: 'LOADING',
      };
    }
    case 'FETCH_SUCCESS': {
      return {
        data: action.payload,
        error: null,
        status: 'SUCCESS',
      };
    }
    case 'FETCH_ERROR': {
      return {
        data: state.data,
        error: action.payload,
        status: 'ERROR',
      };
    }
    default:
      return state;
  }
};

export default reducer;
