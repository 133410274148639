import { useAuth0 } from '@auth0/auth0-react';
import {
  Button,
  ContextMenuItemType,
  GlobalStyles,
  HeaderWithApplicationLinks,
  LanguageSelectorType,
  LoadingIndicator,
  NavigationItemType,
  UserMenuType,
} from '@finbb/ui-components';
import { memo, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import LogoutButton from '../../components/LogoutButton/LogoutButton';
import { StateContext } from '../../context/StateProvider/StateProvider';
import { LanguageType, languages } from '../../i18n/i18n.types';
import NavigationDefinitions from '../../routes/NavigationDefinitions';
import { OwnUser } from '../../routes/RouteDefinitions';
import { ComponentWithChildren } from '../../types/Component.types';
import { getAppLinksForRole } from '../../utils/links';
import { isAllowedRoute } from '../../utils/routes';
import { PlatformWebStyles, StyledBasicLayout, StyledMainContent } from './BasicLayout.styles';

const BasicLayout = ({ children }: ComponentWithChildren) => {
  const { isAuthenticated } = useAuth0();
  const { dispatch, state } = useContext(StateContext);
  const { t, i18n } = useTranslation();

  const languageSelectorItems: ContextMenuItemType[] = languages.map((language) => ({
    content: (
      <Button
        isFullWidth
        onClick={() => i18n.changeLanguage(language)}
        text={t(`Languages.${language}`)}
        variant="UNOBTRUSIVE"
      />
    ),
    id: language,
  }));

  const languageSelector: LanguageSelectorType = {
    items: languageSelectorItems,
    currentLanguage: i18n.language as LanguageType,
  };

  const userMenuItems = [
    { content: <Link to={OwnUser.path}>{t('Routes.account')}</Link>, id: 'accountLink' },
    { content: <LogoutButton variant="LINK" />, id: 'logoutButton' },
  ];

  const userMenu: UserMenuType | undefined = state.user
    ? {
        items: userMenuItems,
        text: state.user?.firstName,
      }
    : undefined;

  const navigationItems: NavigationItemType[] = NavigationDefinitions.filter((route) =>
    isAllowedRoute(route, state.permissions)
  ).map((route) => ({
    path: route.path,
    title: t(route.titleKey) as string,
  }));

  useEffect(() => {
    const handleMouseDownOnce = () => {
      dispatch({ type: 'SET_TABBING_AROUND', payload: false });
      window.removeEventListener('mousedown', handleMouseDownOnce);
      // Since our handlers refer to each other, one of them has to be used before being defined...
      // eslint-disable-next-line
      window.addEventListener('keydown', handleTabKeyDownOnce);
    };

    const handleTabKeyDownOnce = (event: KeyboardEvent) => {
      if (event.key === 'Tab') {
        dispatch({ type: 'SET_TABBING_AROUND', payload: true });
        window.removeEventListener('keydown', handleTabKeyDownOnce);
        window.addEventListener('mousedown', handleMouseDownOnce);
      }
    };

    window.addEventListener('keydown', handleTabKeyDownOnce);

    return () => {
      window.removeEventListener('keydown', handleTabKeyDownOnce);
      window.removeEventListener('mousedown', handleMouseDownOnce);
    };
    // React guarantees that dispatch function identity is stable and won’t change on re-renders.
    // This is why it’s safe to omit from the useEffect or useCallback dependency list.
    // source: https://reactjs.org/docs/hooks-reference.html#usereducer
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userRoles = state.user?.roles || [];
  const applicationLinks = getAppLinksForRole(userRoles, t);

  return (
    <>
      <GlobalStyles />
      <PlatformWebStyles />
      <HeaderWithApplicationLinks
        applicationName={t('Common.applicationName')}
        applicationLinks={applicationLinks}
        isAuthenticated={isAuthenticated}
        navigationItems={navigationItems}
        languageSelector={languageSelector}
        userMenu={userMenu}
      />
      <StyledBasicLayout>
        {state.pendingCalls > 0 && <LoadingIndicator />}
        <StyledMainContent isInactive={state.pendingCalls > 0}>{children}</StyledMainContent>
      </StyledBasicLayout>
    </>
  );
};

export default memo(BasicLayout);
