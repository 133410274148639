import { OrganisationCategoryType, OrganisationTypeType } from '../types/Organisation.types';
import { ProjectStateType } from '../types/Project.types';
import { RoleType } from '../types/Role.types';
import { UserStateType } from '../types/User.types';

export const languages = ['en', 'fi'] as const;
export type LanguageType = (typeof languages)[number];

export interface TranslationType {
  AccountStates: Record<UserStateType, string>;
  Actions: {
    addNewOrganisation: string;
    addNewProject: string;
    approveUser: string;
    archiveProject: string;
    changePassword: string;
    createNewKeyword: string;
    deleteAccount: string;
    goToPage: string;
    login: string;
    logout: string;
    rejectUser: string;
    save: string;
    showDeleteSomethingButton: string;
    signup: string;
    submit: string;
    toHome: string;
    unarchiveProject: string;
    clear: string;
    giveConsent: string;
  };
  ApplicationNames: {
    cohorts: string;
    platform: string;
    requests: string;
    recruit: string;
    offertool: string;
    researcherUi: string;
  };
  Common: {
    applicationName: string;
  };
  Errors: {
    organisationNotFound: string;
    projectNotFound: string;
    userNotFound: string;
    saveFailed: string;
    signUpFailed: string;
  };
  Headings: {
    addOrganisation: string;
    addProject: string;
    expertProfile: string;
    organisationInformation: string;
    organisationUsers: string;
    projectInformation: string;
    signupPending: string;
    signupSuccess: string;
    userInformation: string;
    welcome: string;
  };
  Labels: {
    address: string;
    automaticallyGenerated: string;
    city: string;
    code: string;
    confirmPassword: string;
    countryCode: string;
    description: string;
    email: string;
    emailCaution: string;
    firstName: string;
    id: string;
    lastName: string;
    name: string;
    noData: string;
    organisation: string;
    organisationCategory: string;
    organisationType: string;
    ownAccount: string;
    password: string;
    phone: string;
    phoneIntlFormat: string;
    preferredLanguage: string;
    projectId: string;
    projectCoordinator: string;
    projectResearcher: string;
    // role - see userRole
    select: string;
    someoneElsesAccount: string;
    title: string;
    userRole: string;
    zip: string;
    state: string;
    keywords: string;
    showKeywords: string;
    relatedBiobank: string;
    prefilteredResults: string;
    collaborationOrganisation: string;
    isSideUser: string;
    hasExpertProfile: string;
    primary: string;
    secondary: string;
    icd10Codes: string;
    roles: string;
    researchExpertises: string;
    researchPhases: string;
    therapyAreas: string;
    rareNeurologicalDiseases: string;
    enabled: string;
    disabled: string;
  };
  Languages: Record<LanguageType, string>;
  Messages: {
    confirmOwnAccountDeletion: string;
    confirmPasswordChange: string;
    confirmProjectArchival: string;
    confirmProjectUnarchival: string;
    confirmUserReject: string;
    signupSuccess: string;
    signupPending: string;
    informationSaved: string;
    welcome: string;
    expertProfileNotYourOwnConsentNotGiven: string;
    expertProfileConsent: string;
  };
  OrganisationCategories: Record<OrganisationCategoryType, string>;
  OrganisationTypes: Record<OrganisationTypeType, string>;
  Placeholders: {
    search: string;
  };
  ProjectStates: Record<ProjectStateType, string>;
  Roles: Record<RoleType, string>;
  Routes: {
    account: string;
    projects: string;
    organisations: string;
    ownOrganisation: string;
    root: string;
    users: string;
    expertProfiles: string;
    signup: string;
    privacyPolicy: string;
  };
  ValidationErrors: {
    countryCode: string;
    email: string;
    invalidPhoneNumber: string;
    passwordMustHaveLowercase: string;
    passwordMustHaveNumber: string;
    passwordMustHaveSpecialChar: string;
    passwordMustHaveUppercase: string;
    passwordsMustMatch: string;
    required: string;
    stringTooLong: string;
    stringTooShort: string;
  };
}

export type LocalizationType = Record<LanguageType, TranslationType>;
