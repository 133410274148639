import { object, SchemaOf } from 'yup';
import { OrganisationShape } from '../../schemas/Organisation.schemas';
import { PasswordShape, UserShape } from '../../schemas/User.schemas';
import { SignupFormContentType } from './SignupForm.types';

// eslint-disable-next-line
const { organisationCategory, code, ...SuggestedOrganisationShape } = OrganisationShape;

export const SignupFormSchema: SchemaOf<SignupFormContentType> = object({
  ...UserShape,
  ...PasswordShape,
  suggestedOrganisation: object({
    ...SuggestedOrganisationShape,
  }),
});
