import { InferType, object, string } from 'yup';
import { RoleShape, UserShape } from '../../schemas/User.schemas';

export const UserInfoFormSchema = object({
  ...UserShape,
  ...RoleShape,
  relatedBiobankId: string()
    .uuid()
    .when('roles', {
      is: (roles: string[]) => roles.includes('related-biobank-steering-group'),
      then: string().uuid().required(),
    }),
});

export type UserInfoFormContentType = InferType<typeof UserInfoFormSchema>;
