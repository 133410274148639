import { ButtonGroup, ButtonType, Input, Select, SelectOptionType } from '@finbb/ui-components';
import { Formik } from 'formik';
import { memo, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { StateContext } from '../../context/StateProvider/StateProvider';
import useEndpoint from '../../hooks/useEndpoint';
import { OrganisationApi, ProjectApi, UserApi } from '../../routes/ApiDefinitions';
import { Projects } from '../../routes/RouteDefinitions';
import { OrganisationDto } from '../../types/Organisation.types';
import {
  CreateProjectDto,
  ProjectDto,
  ProjectStateType,
  UpdateProjectDto,
} from '../../types/Project.types';
import { UserDto, userState } from '../../types/User.types';
import {
  formatOrganisationLocation,
  formatUserName,
  getChangedFormikValue,
} from '../../utils/data';
import { ProjectFormSchema } from './ProjectForm.schemas';
import { StyledFieldSet, StyledHeading, StyledProjectForm } from './ProjectForm.styles';
import { ProjectFormContentType, ProjectFormType, UserOptionType } from './ProjectForm.types';

const ProjectForm = ({ project }: ProjectFormType) => {
  const { t } = useTranslation();
  const { state } = useContext(StateContext);
  const history = useNavigate();

  const [selectedOrganisationId, setSelectedOrganisationId] = useState<string | undefined>(
    project?.organisationId
  );

  const isUpdatePage = Boolean(project?.id);

  const [createResponse, createProject] = useEndpoint<ProjectDto>(
    {
      method: 'POST',
      url: ProjectApi.path,
    },
    {} as ProjectDto
  );

  const [patchResponse, patchProject] = useEndpoint<ProjectDto>(
    {
      method: 'PATCH',
      url: `${ProjectApi.path}/${project?.id}`,
    },
    {} as ProjectDto
  );

  const [{ data: organisations }, getOrganisations] = useEndpoint<OrganisationDto[]>(
    {
      method: 'GET',
      url: OrganisationApi.path,
      queryParams: {
        organisationCategory: 'customer',
      },
    },
    []
  );

  const [{ data: researcherUsers }] = useEndpoint<UserDto[]>(
    {
      method: 'GET',
      options: { callImmediately: true },
      queryParams: {
        organisationId: selectedOrganisationId,
        state: 'accepted',
      },
      url: UserApi.path,
    },
    [],
    selectedOrganisationId
  );

  const [{ data: coordinatorUsers }] = useEndpoint<UserDto[]>(
    {
      method: 'GET',
      options: { callImmediately: true },
      queryParams: {
        role: 'finbb-coordinator|organisation-coordinator',
        state: userState.accepted,
      },
      url: UserApi.path,
    },
    []
  );

  const userMapFunction = (user: UserDto) => ({
    name: `${formatUserName(user)} (${user.email.substring(user.email.indexOf('@'))})`,
    organisationId: user.organisationId as string,
    value: user.auth0Id,
  });

  const initialState: ProjectFormContentType = {
    description: project?.description || '',
    organisationId:
      project?.organisationId ||
      (!state.permissions?.includes('read:all-organisations') && state.user?.organisationId) ||
      '',
    projectId: project?.projectId || '',
    projectCoordinatorAuth0Id: project?.projectCoordinator?.auth0Id || '',
    projectResearcherAuth0Id: project?.projectResearcher?.auth0Id || '',
  };

  const organisationOptions: SelectOptionType[] =
    organisations?.map((organisation) => ({
      name: `${organisation.name} ∙ ${formatOrganisationLocation(organisation)}`,
      value: organisation.id,
    })) || [];

  const coordinatorUserOptions: UserOptionType[] = coordinatorUsers?.map(userMapFunction) || [];

  const [researcherUserOptions, setResearcherUserOptions] = useState(
    researcherUsers?.map(userMapFunction) || []
  );

  const handleSubmit = async (
    values: ProjectFormContentType,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    if (isUpdatePage) {
      const submitValues: UpdateProjectDto = {
        ...getChangedFormikValue('description', values, initialState),
        ...getChangedFormikValue('organisationId', values, initialState),
        ...getChangedFormikValue('projectCoordinatorAuth0Id', values, initialState),
        ...getChangedFormikValue('projectResearcherAuth0Id', values, initialState),
      };

      if (patchResponse.status === 'IDLE') {
        await patchProject(submitValues);
        setSubmitting(false);
      }
    } else {
      const submitValues: CreateProjectDto = {
        description: values.description,
        organisationId: values.organisationId,
        projectCoordinatorAuth0Id: values.projectCoordinatorAuth0Id,
        projectResearcherAuth0Id: values.projectResearcherAuth0Id,
      };

      if (createResponse.status === 'IDLE') {
        await createProject(submitValues);
        setSubmitting(false);
      }
    }

    history(Projects.path);
  };

  const changeProjectState = async (newState: ProjectStateType) => {
    const projectState: UpdateProjectDto = {
      state: newState,
    };

    // TODO: Change this to a custom modal component once we have one available in @finbb/ui-components
    // eslint-disable-next-line no-alert
    const isConfirmed = window.confirm(
      t(
        newState === 'archived'
          ? 'Messages.confirmProjectArchival'
          : 'Messages.confirmProjectUnarchival'
      )
    );

    if (isConfirmed) {
      await patchProject(projectState);
      history(Projects.path);
    }
  };

  const handleArchiveClick = async () => changeProjectState('archived');
  const handleUnarchiveClick = async () => changeProjectState('active');
  const isArchived = project?.state === 'archived';

  const buttons: ButtonType[] = [
    ...(isUpdatePage
      ? ([
          {
            text: t(isArchived ? 'Actions.unarchiveProject' : 'Actions.archiveProject'),
            type: 'button',
            onClick: isArchived ? handleUnarchiveClick : handleArchiveClick,
            variant: 'UNOBTRUSIVE',
          },
        ] as ButtonType[])
      : []),
    {
      text: t(isUpdatePage ? 'Actions.save' : 'Actions.submit'),
      type: 'submit',
      variant: 'CALL_TO_ACTION',
    },
  ];

  // Update selected organisation id with a custom callback...
  const handleOrganisationChange = (
    event: React.ChangeEvent,
    handleChange: React.ChangeEventHandler
  ) => {
    handleChange(event);
    const target = event.target as HTMLInputElement;
    setSelectedOrganisationId(target.value);
  };

  // ..and then update researcher select options after researcherUsers
  // have been re-fetched from the API. The custom hook useEndpoint for
  // researcherUsers listens to changes in selectedOrganisationId.
  useEffect(() => {
    setResearcherUserOptions(researcherUsers?.map(userMapFunction) || []);
  }, [researcherUsers]);

  useEffect(() => {
    if (state.permissions?.includes('read:all-organisations')) {
      getOrganisations();
    }
  }, [getOrganisations, state.permissions]);

  const projectIdField = 'projectId';

  return (
    <Formik
      initialValues={initialState}
      onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
      validationSchema={ProjectFormSchema}
    >
      {({ errors, handleBlur, handleChange, touched, values }) => (
        <StyledProjectForm>
          <StyledHeading>
            {t(isUpdatePage ? 'Headings.projectInformation' : 'Headings.addProject')}
          </StyledHeading>

          <StyledFieldSet>
            {state.permissions?.includes('read:all-organisations') && (
              <Select
                label={t('Labels.organisation')}
                name="organisationId"
                onBlur={handleBlur}
                onChange={(event) => {
                  handleOrganisationChange(event, handleChange);
                }}
                options={organisationOptions}
                placeholder={t('Labels.select')}
                required
                value={values.organisationId}
              />
            )}
            <Input
              label={t('Labels.projectId')}
              message={errors.description && touched.description ? errors.description : ''}
              name={projectIdField}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={t('Labels.automaticallyGenerated')}
              readOnly
              value={values.projectId}
              variant={errors.description && touched.description ? 'CAUTION' : undefined}
            />
            <Input
              label={t('Labels.title')}
              message={errors.description && touched.description ? errors.description : ''}
              name="description"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={t('Labels.title')}
              required
              value={values.description}
              variant={errors.description && touched.description ? 'CAUTION' : undefined}
            />
            <Select
              label={t('Labels.projectResearcher')}
              name="projectResearcherAuth0Id"
              onBlur={handleBlur}
              onChange={handleChange}
              options={researcherUserOptions}
              placeholder={t('Labels.select')}
              required
              value={values.projectResearcherAuth0Id}
            />
            <Select
              label={t('Labels.projectCoordinator')}
              name="projectCoordinatorAuth0Id"
              onBlur={handleBlur}
              onChange={handleChange}
              options={coordinatorUserOptions}
              placeholder={t('Labels.select')}
              required
              value={values.projectCoordinatorAuth0Id}
            />
          </StyledFieldSet>

          <ButtonGroup buttons={buttons} amountOfButtonsOnRight={1} />
        </StyledProjectForm>
      )}
    </Formik>
  );
};

export default memo(ProjectForm);
