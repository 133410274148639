import { useAuth0 } from '@auth0/auth0-react';
import { memo, useEffect } from 'react';

const LogoutPage = () => {
  const { logout } = useAuth0();

  useEffect(() => {
    logout();
  }, [logout]);

  return <div />;
};

export default memo(LogoutPage);
