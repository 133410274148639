import { LinkButton } from '@finbb/ui-components';
import { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import ProjectForm from '../../components/ProjectForm/ProjectForm';
import ProjectList from '../../components/ProjectList/ProjectList';
import ProjectSettings from '../../components/ProjectSettings/ProjectSettings';
import { StateContext } from '../../context/StateProvider/StateProvider';
import { Projects } from '../../routes/RouteDefinitions';
import { RouteActions } from '../../types/Route.types';
import { getRouteActionPath } from '../../utils/routes';

const ProjectManagementPage = () => {
  const { t } = useTranslation();
  const { state } = useContext(StateContext);
  const canCreateNewProjects = state.permissions?.includes('create:all-projects');

  return (
    <Routes>
      <Route path={RouteActions.CREATE} element={<ProjectForm />} />
      <Route path=":id" element={<ProjectSettings />} />
      <Route
        path=""
        element={
          <>
            <ProjectList />
            {canCreateNewProjects && (
              <LinkButton
                to={getRouteActionPath(Projects, RouteActions.CREATE)}
                text={t('Actions.addNewProject')}
              />
            )}
          </>
        }
      />
    </Routes>
  );
};

export default memo(ProjectManagementPage);
