import styled from 'styled-components';

export const StyledExpertProfileList = styled.div`
  ${({ theme }) => theme.mixins.Container('COLUMN')}
`;

export const StyledPerfilterNotice = styled.div`
  ${({ theme }) => theme.mixins.Container('ROW', { alignItems: 'center' })}
  background-color: ${({ theme }) => theme.values.colors.background.default.HIGHLIGHT};
  padding: ${({ theme }) => theme.values.spacing.MEDIUM};
  color: ${({ theme }) => theme.values.colors.text.ACCENT};
  border-color: ${({ theme }) => theme.values.colors.stroke.caution.BASE};
  border-radius: ${({ theme }) => theme.values.radii.MEDIUM};
  border-width: ${({ theme }) => theme.values.strokeWidths.MEDIUM};
  border-style: solid;
  top: 8rem;
  left: ${({ theme }) => theme.values.spacing.MEDIUM};
  right: ${({ theme }) => theme.values.spacing.MEDIUM};
  max-width: 40rem;
  margin: 0 auto;
`;

export const StyledPerfilterNoticeText = styled.div`
  flex: 1;
  font-weight: ${({ theme }) => theme.values.fontWeights.MEDIUM};
`;

export const HighlightContainer = styled.div`
  max-width: 350px;
`;
