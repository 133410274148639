import { Button, Checkbox } from '@finbb/ui-components';
import { Formik, FormikState } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth0 } from '@auth0/auth0-react';
import useEndpoint from '../../hooks/useEndpoint';
import { UserApi } from '../../routes/ApiDefinitions';
import { ExpertProfileDto, ExportProfileCategory } from '../../types/ExpertProfile.types';
import { getChangedFormikValue } from '../../utils/data';
import CategoryMultiSelect from './CategoryMultiSelect';
import { ExpertProfileFormSchema } from './ExpertProfileForm.schemas';
import {
  StyledButtonRow,
  StyledErrorMessage,
  StyledExpertProfileForm,
  StyledFieldSet,
  StyledForm,
  StyledHeading,
  StyledRow,
  StyledSuccessMessage,
} from './ExpertProfileForm.styles';
import { ExpertProfileContentType, ExpertProfileFormType } from './ExpertProfileForm.types';
import KeywordMultiSelect from './KeywordMultiSelect';

const ExpertProfileForm = ({ user, expertProfile, reloadExpertProfile }: ExpertProfileFormType) => {
  const { t } = useTranslation();
  const { user: authenticatedUser } = useAuth0();
  const hasUserGivenConsentToCreateExpertProfile =
    user.expertProfileCreationConsentGivenAt !== null;
  const [displayConsentPrompt, setDisplayConsentPrompt] = useState(
    !hasUserGivenConsentToCreateExpertProfile
  );

  const initialState: ExpertProfileContentType = {
    enabled: expertProfile?.enabled,
    keywords: expertProfile?.keywords?.map((keyword) => keyword.value) || [],
    researchPhases: expertProfile?.researchPhases?.map((entry) => entry.id),
    researchExpertises: expertProfile?.researchExpertises?.map((entry) => entry.id),
    roles: expertProfile?.roles?.map((entry) => entry.id),
    therapyAreas: expertProfile?.therapyAreas?.map((entry) => entry.id),
    icd10Codes: expertProfile?.icd10Codes?.map((entry) => entry.id),
    rareNeurologicalDiseases: expertProfile?.rareNeurologicalDiseases?.map((entry) => entry.id),
  };

  const [createResponse, createExpertProfile] = useEndpoint<ExpertProfileDto>(
    {
      method: 'POST',
      url: `${UserApi.path}/${user?.id}/expert-profile`,
    },
    {} as ExpertProfileDto
  );

  const [patchResponse, patchExpertProfile] = useEndpoint<ExpertProfileDto>(
    {
      method: 'PATCH',
      url: `${UserApi.path}/${user?.id}/expert-profile`,
    },
    {} as ExpertProfileDto
  );

  const isUpdatePage = expertProfile !== undefined;

  const upsertFunction = isUpdatePage ? patchExpertProfile : createExpertProfile;
  const upsertResponse = isUpdatePage ? patchResponse : createResponse;

  const handleSubmit = async (
    values: ExpertProfileContentType,
    setSubmitting: (isSubmitting: boolean) => void,
    resetForm: (nextState?: Partial<FormikState<ExpertProfileContentType>> | undefined) => void
  ) => {
    const upsertValues = {
      ...getChangedFormikValue('enabled', values, initialState),
      ...getChangedFormikValue('keywords', values, initialState),
      ...getChangedFormikValue('researchPhases', values, initialState),
      ...getChangedFormikValue('researchExpertises', values, initialState),
      ...getChangedFormikValue('roles', values, initialState),
      ...getChangedFormikValue('therapyAreas', values, initialState),
      ...getChangedFormikValue('icd10Codes', values, initialState),
      ...getChangedFormikValue('rareNeurologicalDiseases', values, initialState),
    };

    if (upsertResponse.status !== 'LOADING') {
      await upsertFunction(upsertValues);
      setSubmitting(false);

      resetForm({
        values,
      });

      if (reloadExpertProfile) {
        reloadExpertProfile();
      }
    }
  };

  if (user.auth0Id !== authenticatedUser?.sub && displayConsentPrompt) {
    return (
      <StyledExpertProfileForm>
        <StyledRow>
          <StyledHeading>{t('Headings.expertProfile')}</StyledHeading>
        </StyledRow>
        <StyledRow>
          <p>{t('Messages.expertProfileNotYourOwnConsentNotGiven')}</p>
        </StyledRow>
        <StyledRow>
          <Button
            text={t('Actions.giveConsent')}
            type="button"
            variant="CALL_TO_ACTION"
            onClick={() => {
              setDisplayConsentPrompt(false);
            }}
          />
        </StyledRow>
      </StyledExpertProfileForm>
    );
  }

  if (displayConsentPrompt) {
    return (
      <StyledExpertProfileForm>
        <StyledRow>
          <StyledHeading>{t('Headings.expertProfile')}</StyledHeading>
        </StyledRow>
        <StyledRow>
          <p>{t('Messages.expertProfileConsent')}</p>
        </StyledRow>
        <StyledRow>
          <Button
            text={t('Actions.giveConsent')}
            type="button"
            variant="CALL_TO_ACTION"
            onClick={() => {
              setDisplayConsentPrompt(false);
            }}
          />
        </StyledRow>
      </StyledExpertProfileForm>
    );
  }

  return (
    <StyledExpertProfileForm>
      <StyledRow>
        <StyledHeading>{t('Headings.expertProfile')}</StyledHeading>
      </StyledRow>

      <Formik
        initialValues={initialState}
        onSubmit={(values, { setSubmitting, resetForm }) =>
          handleSubmit(values, setSubmitting, resetForm)
        }
        validationSchema={ExpertProfileFormSchema}
      >
        {({ dirty, values, isSubmitting, handleChange, isValid }) => (
          <StyledForm>
            {isUpdatePage && (
              <StyledFieldSet>
                <Checkbox
                  label={t('Labels.enabled')}
                  name="enabled"
                  isChecked={values.enabled}
                  onChange={handleChange}
                />
              </StyledFieldSet>
            )}

            <StyledFieldSet>
              <KeywordMultiSelect
                label={t('Labels.keywords')}
                name="keywords"
                placeholder={t('Labels.select')}
                value={values.keywords ?? []}
              />
            </StyledFieldSet>

            <StyledFieldSet>
              <CategoryMultiSelect
                category={ExportProfileCategory.TherapyAreas}
                label={t('Labels.therapyAreas')}
                name="therapyAreas"
                placeholder={t('Labels.select')}
                value={values.therapyAreas ?? []}
              />
            </StyledFieldSet>

            <StyledFieldSet>
              <CategoryMultiSelect
                category={ExportProfileCategory.ResearchExpertises}
                label={t('Labels.researchExpertises')}
                name="researchExpertises"
                placeholder={t('Labels.select')}
                value={values.researchExpertises ?? []}
              />
            </StyledFieldSet>

            <StyledFieldSet>
              <CategoryMultiSelect
                category={ExportProfileCategory.Roles}
                label={t('Labels.roles')}
                name="roles"
                placeholder={t('Labels.select')}
                value={values.roles ?? []}
              />
            </StyledFieldSet>

            <StyledFieldSet>
              <CategoryMultiSelect
                category={ExportProfileCategory.ResearchPhases}
                label={t('Labels.researchPhases')}
                name="researchPhases"
                placeholder={t('Labels.select')}
                value={values.researchPhases ?? []}
              />
            </StyledFieldSet>

            <StyledFieldSet>
              <CategoryMultiSelect
                category={ExportProfileCategory.Icd10Codes}
                label={t('Labels.icd10Codes')}
                name="icd10Codes"
                placeholder={t('Labels.select')}
                value={values.icd10Codes ?? []}
              />
            </StyledFieldSet>
            <StyledFieldSet>
              <CategoryMultiSelect
                category={ExportProfileCategory.RareNeurologicalDiseases}
                label={t('Labels.rareNeurologicalDiseases')}
                name="rareNeurologicalDiseases"
                placeholder={t('Labels.select')}
                value={values.rareNeurologicalDiseases ?? []}
              />
            </StyledFieldSet>
            <StyledButtonRow>
              <Button
                disabled={!isValid || !dirty || isSubmitting}
                text={t('Actions.save')}
                type="submit"
                variant="CALL_TO_ACTION"
              />
            </StyledButtonRow>
            {upsertResponse.status === 'SUCCESS' && (
              <StyledSuccessMessage>{t('Messages.informationSaved')}</StyledSuccessMessage>
            )}
            {upsertResponse.status === 'ERROR' && (
              <StyledErrorMessage>{t('Errors.saveFailed')}</StyledErrorMessage>
            )}
          </StyledForm>
        )}
      </Formik>
    </StyledExpertProfileForm>
  );
};

export default ExpertProfileForm;
