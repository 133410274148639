import { LinkButton } from '@finbb/ui-components';
import { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import OrganisationForm from '../../components/OrganisationForm/OrganisationForm';
import OrganisationList from '../../components/OrganisationList/OrganisationList';
import OrganisationSettings from '../../components/OrganisationSettings/OrganisationSettings';
import { StateContext } from '../../context/StateProvider/StateProvider';
import { RouteActions } from '../../types/Route.types';

const OrganisationManagementPage = () => {
  const { t } = useTranslation();
  const { state } = useContext(StateContext);
  const canCreateNewOrganisations = state.permissions?.includes('create:all-organisations');

  return (
    <Routes>
      <Route path={RouteActions.CREATE} element={<OrganisationForm />} />
      <Route path=":id" element={<OrganisationSettings />} />
      <Route
        path=""
        element={
          <>
            <OrganisationList />
            {canCreateNewOrganisations && (
              <LinkButton to={RouteActions.CREATE} text={t('Actions.addNewOrganisation')} />
            )}
          </>
        }
      />
    </Routes>
  );
};

export default memo(OrganisationManagementPage);
