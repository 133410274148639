import { useAuth0 } from '@auth0/auth0-react';
import { memo } from 'react';
import { BrowserRouter } from 'react-router-dom';
import BasicLayout from '../../layouts/BasicLayout/BasicLayout';
import ErrorMessage from '../ErrorMessage';
import AuthenticatedSwitch from './AuthenticatedSwitch';
import UnauthenticatedSwitch from './UnauthenticatedSwitch';

const Router = () => {
  const { isAuthenticated, isLoading } = useAuth0();

  const RouteContent = () => {
    if (isLoading) {
      return <div />;
    }

    return isAuthenticated ? <AuthenticatedSwitch /> : <UnauthenticatedSwitch />;
  };

  return (
    <BrowserRouter>
      <BasicLayout>
        <RouteContent />
      </BasicLayout>
      <ErrorMessage />
    </BrowserRouter>
  );
};

export default memo(Router);
