import { ButtonGroup, ButtonType, Select, SelectOptionType } from '@finbb/ui-components';
import { Formik } from 'formik';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useEndpoint from '../../hooks/useEndpoint';
import { OrganisationApi, UserApi } from '../../routes/ApiDefinitions';
import { Users } from '../../routes/RouteDefinitions';
import {
  AddToOrganisationDto,
  OrganisationDto,
  OrganisationTypeType,
} from '../../types/Organisation.types';
import { AddRoleDto, userRoles, RoleType } from '../../types/Role.types';
import { UserDto } from '../../types/User.types';
import { XhrResponseType } from '../../types/XmlHttpRequest.types';
import { formatOrganisationLocation, formatUserName } from '../../utils/data';
import { UserApprovalFormSchema } from './UserApprovalForm.schemas';
import {
  StyledFieldSet,
  StyledForm,
  StyledLabel,
  StyledSuggestedData,
  StyledSuggestion,
  StyledUserApprovalForm,
  StyledValue,
} from './UserApprovalForm.styles';
import { UserApprovalFormContentType, UserApprovalFormType } from './UserApprovalForm.types';

const UserApprovalForm = ({ user }: UserApprovalFormType) => {
  const history = useNavigate();
  const { t } = useTranslation();

  const [, approveUser] = useEndpoint<UserDto>(
    {
      method: 'PUT',
      url: `${UserApi.path}/${user.auth0Id}/organisation`,
    },
    {} as UserDto
  );

  const [, putUserRole] = useEndpoint<XhrResponseType>(
    {
      method: 'PUT',
      url: `${UserApi.path}/${user.auth0Id}/role`,
    },
    '200'
  );

  const [, rejectUser] = useEndpoint<XhrResponseType>(
    {
      method: 'PUT',
      url: `${UserApi.path}/${user.auth0Id}/reject`,
    },
    '200'
  );

  const [{ data: organisations }] = useEndpoint<OrganisationDto[]>(
    {
      method: 'GET',
      options: { callImmediately: true },
      url: OrganisationApi.path,
    },
    []
  );

  const initialState: UserApprovalFormContentType = {
    organisationId: '',
    role: 'researcher',
  };

  const organisationOptions: SelectOptionType[] =
    organisations?.map((organisation) => ({
      name: `${organisation.name} ∙ ${formatOrganisationLocation(organisation)}`,
      value: organisation.id,
    })) || [];

  const roleOptions: SelectOptionType<RoleType>[] = userRoles.map((role) => ({
    name: t(`Roles.${role}`),
    value: role,
  }));

  const handleRejectClick = async () => {
    // TODO: Change this to a custom modal component once we have one available in @finbb/ui-components
    // eslint-disable-next-line no-alert
    const isConfirmed = window.confirm(t('Messages.confirmUserReject'));

    if (isConfirmed) {
      await rejectUser();
      history(Users.path);
    }
  };

  const handleSubmit = async (values: UserApprovalFormContentType) => {
    const roleDto: AddRoleDto = {
      roles: [values.role],
    };

    const organisationDto: AddToOrganisationDto = {
      organisationId: values.organisationId,
    };

    await putUserRole(roleDto);
    await approveUser(organisationDto);

    history(Users.path);
  };

  return (
    <StyledUserApprovalForm>
      <StyledSuggestion>
        <StyledSuggestedData>
          <div>
            <StyledLabel>{t('Labels.name')}</StyledLabel>
            <StyledValue>{formatUserName(user)}</StyledValue>
          </div>
          <div>
            <StyledLabel>{t('Labels.email')}</StyledLabel>
            <StyledValue>{user.email}</StyledValue>
          </div>
          <div>
            <StyledLabel>{t('Labels.phone')}</StyledLabel>
            <StyledValue>{user.phone}</StyledValue>
          </div>
          <div>
            <StyledLabel>{t('Labels.preferredLanguage')}</StyledLabel>
            <StyledValue>{user.lang}</StyledValue>
          </div>
        </StyledSuggestedData>

        <StyledSuggestedData>
          <div>
            <StyledLabel>{t('Labels.organisation')}</StyledLabel>
            <StyledValue>{user.suggestedOrganisation?.name}</StyledValue>
          </div>
          <div>
            <StyledLabel>{t('Labels.organisationType')}</StyledLabel>
            <StyledValue>
              {t(
                `OrganisationTypes.${
                  user.suggestedOrganisation?.organisationType as OrganisationTypeType
                }`
              )}
            </StyledValue>
          </div>
          <div>
            <StyledLabel>{t('Labels.address')}</StyledLabel>
            <StyledValue>{user.suggestedOrganisation?.address}</StyledValue>
          </div>
          <div>
            <StyledLabel>{t('Labels.zip')}</StyledLabel>
            <StyledValue>{user.suggestedOrganisation?.zip}</StyledValue>
          </div>
          <div>
            <StyledLabel>{t('Labels.city')}</StyledLabel>
            <StyledValue>{user.suggestedOrganisation?.city}</StyledValue>
          </div>
          <div>
            <StyledLabel>{t('Labels.countryCode')}</StyledLabel>
            <StyledValue>{user.suggestedOrganisation?.countryCode}</StyledValue>
          </div>
        </StyledSuggestedData>
      </StyledSuggestion>

      <Formik
        initialValues={initialState}
        onSubmit={handleSubmit}
        validationSchema={UserApprovalFormSchema}
      >
        {/* eslint-disable-next-line */}
        {({ dirty, handleBlur, handleChange, isSubmitting, isValid, values }) => {
          const buttons: ButtonType[] = [
            {
              disabled: isSubmitting,
              onClick: handleRejectClick,
              text: t('Actions.rejectUser'),
              type: 'button',
              variant: 'UNOBTRUSIVE_CAUTION',
            },
            {
              disabled: !(isValid && dirty) || isSubmitting,
              text: t('Actions.approveUser'),
              type: 'submit',
              variant: 'CALL_TO_ACTION',
            },
          ];

          return (
            <StyledForm>
              <StyledFieldSet>
                <Select
                  label={t('Labels.organisation')}
                  name="organisationId"
                  onChange={handleChange}
                  options={organisationOptions}
                  placeholder={t('Labels.select')}
                  required
                  value={values.organisationId}
                />

                <Select
                  label={t('Labels.userRole')}
                  name="role"
                  onChange={handleChange}
                  options={roleOptions}
                  placeholder={t('Labels.select')}
                  required
                  value={values.role}
                />
              </StyledFieldSet>

              <ButtonGroup buttons={buttons} amountOfButtonsOnRight={1} />
            </StyledForm>
          );
        }}
      </Formik>
    </StyledUserApprovalForm>
  );
};

export default memo(UserApprovalForm);
