import { Form } from 'formik';
import styled, { css } from 'styled-components';

export const StyledHeading = styled.p`
  ${({ theme }) => theme.objects.Heading.Scale.MEDIUM}
  ${({ theme }) => theme.objects.Heading.Style.Primary}
`;

export const StyledFieldSet = styled.fieldset`
  ${({ theme }) => theme.objects.Fieldset.Base}
`;

export const StyledFieldRow = styled(StyledFieldSet)`
  ${({ theme }) =>
    theme.mixins.RespondTo(
      'LAPTOP',
      css`
        ${theme.mixins.Container('ROW', { alignItems: 'flex-start' })}};
      `
    )}

  > * {
    width: 100%;
  }
`;

export const StyledOrganisationForm = styled(Form)`
  ${({ theme }) => theme.mixins.Container('COLUMN')}
`;
